import styled from 'styled-components';

import CommitteesCard from '../CommitteesCard/CommitteesCard';

import IITK from '../../../assets/Committees/Camp/IITK.jpeg';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 90vw;
  margin-top: 50px;
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 10vh;
`;

const Title = styled.h1`
  color: #0b3d59;
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  margin: 0px 0px 50px 0px;
  text-align: center;
`;

const CampusAmbassadors = () => {
  return (
    <MainContainer>
      <Container>
        <Title>Campus Ambassadors</Title>
        <Cards>
          <CommitteesCard
            BackgroundImage={IITK}
            CommitteesName="Indian Institute of Technology Kanpur"
          />
        </Cards>
      </Container>
    </MainContainer>
  );
};

export default CampusAmbassadors;
