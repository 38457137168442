import styled from 'styled-components';
import { Divider, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

import logo from '../../assets/logo-dark.png';
import Phone from '../../assets/Icons/phone.svg';
import Facebook from '../../assets/Icons/facebook.svg';
import Instagram from '../../assets/Icons/instagram.svg';
import Linkedin from '../../assets/Icons/linkedin.svg';
import Twitter from '../../assets/Icons/twitter.svg';
import UpArrow from '../../assets/Icons/up-arrow.svg';

const MainContainer = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 80vw;
  height: auto;
`;

const Title = styled.h1`
  font-size: ${(props) => props.fontSize};
  font-family: 'Futura Std Heavy';
  color: #0b3d59;
  margin: ${(props) => props.margin || 0}px 0px;
`;

const RegularText = styled.p`
  font-size: ${(props) => props.fontSize};
  font-family: 'Futura Std Book';
  color: #0b3d59;
  margin: ${(props) => props.margin || 0}px 0px;
`;

const PhoneContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 30px;
`;

const ContactSection = styled.div``;

const Logo = styled.div`
  margin: 20px 0px;
`;

const AboutSection = styled.div``;

const StudentsSection = styled.div``;

const EmployeesSection = styled.div``;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  flex-wrap: wrap;
  row-gap: 30px;
`;

const BottomText = styled.p`
  font-size: ${(props) => props.fontSize};
  font-family: 'Futura Std Book';
  color: #0b3d59;
  margin: ${(props) => props.margin || 0}px 0px;
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
`;

const SocialIcons = styled.div``;

const Icon = styled.img`
  height: 1.5rem;
  margin: 0px ${(props) => props.margin || 0};
  margin-left: ${(props) => props.marginLeft || 0};
  margin-right: ${(props) => props.marginRight};
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const Footer = () => {
  return (
    <MainContainer>
      <Container>
        <TopContainer>
          <ContactSection>
            <Logo>
              <img
                src={logo}
                alt="IAESTE India Logo"
                height="54px"
                width="auto"
              />
            </Logo>
            <Title fontSize={'1.2rem'} margin={30}>
              Contact Information:
            </Title>
            <RegularText fontSize={'1rem'} margin={10}>
              IAESTE India
            </RegularText>
            <RegularText fontSize={'1rem'}>
              First Floor, Innovation Center,
            </RegularText>
            <RegularText fontSize={'1rem'}>
              Manipal Institute of Technology, Manipal,
            </RegularText>
            <RegularText fontSize={'1rem'}>Karnataka, 576104</RegularText>
            <PhoneContainer>
              <Icon marginRight={'5px'} src={Phone} />
              <RegularText fontSize={'1rem'}>+91 820 292 5040</RegularText>
            </PhoneContainer>
          </ContactSection>
          <AboutSection>
            <NavLink to="/">
              <Title fontSize={'1.3rem'} margin={20}>
                About
              </Title>
            </NavLink>

            <NavLink to="/about/testimonials">
              <RegularText fontSize={'1.2rem'} margin={20}>
                Testimonials
              </RegularText>
            </NavLink>
            <NavLink to="/about/committees">
              <RegularText fontSize={'1.2rem'} margin={20}>
                Committees
              </RegularText>
            </NavLink>
            <NavLink to="/about/board">
              <RegularText fontSize={'1.2rem'} margin={20}>
                Board
              </RegularText>
            </NavLink>
            <ExternalLink href="https://iaeste.org/" target="_blank">
              <RegularText fontSize={'1.2rem'} margin={20}>
                IAESTE A.S.B.L
              </RegularText>
            </ExternalLink>
          </AboutSection>
          <StudentsSection>
            <NavLink to="/students">
              <Title fontSize={'1.3rem'} margin={20}>
                Students
              </Title>
            </NavLink>
            <NavLink to="/students/how-it-works">
              <RegularText fontSize={'1.2rem'} margin={20}>
                How it works
              </RegularText>
            </NavLink>
            <NavLink to="/students/faq">
              <RegularText fontSize={'1.2rem'} margin={20}>
                FAQs
              </RegularText>
            </NavLink>
            <NavLink to="/students/events">
              <RegularText fontSize={'1.2rem'} margin={20}>
                Events
              </RegularText>
            </NavLink>
            <ExternalLink href="https://iaeste.org/internships" target="_blank">
              <RegularText fontSize={'1.2rem'} margin={20}>
                Internships
              </RegularText>
            </ExternalLink>
            <NavLink to="/students/visa">
              <RegularText fontSize={'1.2rem'} margin={20}>
                Visa Guidelines
              </RegularText>
            </NavLink>
          </StudentsSection>
          <EmployeesSection>
            <NavLink to="/employers/partner-with-us">
              <Title fontSize={'1.3rem'} margin={20}>
                Employers
              </Title>
            </NavLink>
            <NavLink to="/employers/how-it-works">
              <RegularText fontSize={'1.2rem'} margin={20}>
                How it works
              </RegularText>
            </NavLink>
            <NavLink to="/employers/faq">
              <RegularText fontSize={'1.2rem'} margin={20}>
                FAQs
              </RegularText>
            </NavLink>
            <NavLink to="/employers/partner-with-us">
              <RegularText fontSize={'1.2rem'} margin={20}>
                Partner With Us
              </RegularText>
            </NavLink>
          </EmployeesSection>
        </TopContainer>
        <Divider />
        <BottomContainer>
          <BottomText>© 2023 IAESTE India. All Rights Reserved.</BottomText>
          <IconContainer>
            <SocialIcons>
              <IconButton
                aria-label="IAESTE India Facebook"
                href="https://www.facebook.com/iaesteindia/"
                target="_blank"
              >
                <Icon src={Facebook} />
              </IconButton>
              <IconButton
                aria-label="IAESTE India Instagram"
                href="https://www.instagram.com/iaeste_india/?hl=en"
                target="_blank"
              >
                <Icon src={Instagram} />
              </IconButton>
              <IconButton
                aria-label="IAESTE India Linkedin"
                href="https://www.linkedin.com/company/iaeste-india/"
                target="_blank"
              >
                <Icon src={Linkedin} />
              </IconButton>
              <IconButton
                aria-label="IAESTE India Twitter"
                href="https://twitter.com/iaeste_india"
                target="_blank"
              >
                <Icon src={Twitter} />
              </IconButton>
            </SocialIcons>
            <IconButton
              sx={{ marginLeft: '10px' }}
              aria-label="Scroll to Top Icon"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              <Icon src={UpArrow} />
            </IconButton>
          </IconContainer>
        </BottomContainer>
      </Container>
    </MainContainer>
  );
};

export default Footer;
