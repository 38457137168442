import styled from 'styled-components';

const MainContainer = styled.div`
  height: 450px;
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  border-radius: 8px;
  margin: 0;
`;

// const CardContent = styled.p`
//   font-family: 'Futura Std Heavy';
//   font-size: 1.125rem;
//   margin: 0;
//   padding: 0;
//   max-width: 335px;
//   color: #ffffff;
//   ${(props) =>
//     props.isMobile &&
//     css`
//       position: absolute;
//       background-color: rgba(11, 61, 89, 0.6);
//       height: 232px;
//       width: 90vw;
//       border-radius: 8px;
//       text-align: center;
//       display: flex;

//       align-items: center;
//     `};
// `;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 146px;
  width: 100%;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  height: 110px;
  width: 110px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; ;
`;

const CardTitle = styled.h1`
  font-size: 1rem;
  color: #1b75bb;
`;

const CardContent = styled.div`
  margin: 15px 0 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardCountry = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: #0b3d59;
  margin: 10px 0px;
`;

const CardExperience = styled.p`
  margin: 0;
  padding: 0;
  color: #7a7a7a;
  font-size: 0.625rem;
  margin: auto 0px;
`;

const CardEmployer = styled.h3`
  margin: 0;
  padding: 0;
  color: #0b3d59;
  font-size: 0.625rem;
`;

const CardContentTop = styled.div``;

const Card = ({ internImage, internName, country, experience, employer }) => {
  return (
    <MainContainer>
      <CardHeader>
        <ProfileImage src={internImage} alt={internName} />
        <CardTitle>{internName}</CardTitle>
      </CardHeader>
      <CardContent>
        <CardContentTop>
          <CardCountry>{country}</CardCountry>
          <CardExperience>{experience}</CardExperience>
        </CardContentTop>

        <CardEmployer>{employer}</CardEmployer>
      </CardContent>
    </MainContainer>
  );
};

export default Card;
