import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';

const Container = styled.div`
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
  margin: 30px 0px;
  transition: transform 0.3s;
  box-shadow: #fcfdfd 2px 2px 2px 2px;

  &:hover {
    transform: scale(1.02);
  }
  ${(props) => props.isMobile && `height: auto`}
`;

const Illustration = styled.img`
  height: 100%;
  width: 270px;
  height: 270px;
  object-fit: cover;
`;

const Title = styled.h2`
  font-family: 'Futura Std Heavy';
  font-size: 1.125rem;
  margin: 0px;
  margin-bottom: 1rem;
`;

const MinimizedCard = ({ StepIllustration, StepTitle }) => {
  const isMobile = useMediaQuery('(max-width:870px)');

  return (
    <Container isMobile={isMobile}>
      <Illustration isMobile={isMobile} src={StepIllustration} />
      <Title>{StepTitle}</Title>
    </Container>
  );
};

export default MinimizedCard;
