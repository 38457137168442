import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { useMediaQuery } from '@mui/material';
import { Button } from '@mui/material';
import React, { useRef, useState } from 'react'

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0px;
`;

const Container = styled.div`
  width: 90vw;
  background-color: white;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  margin: 20px 0px;
  color: #0b3d59;
  padding: 50px ${(props) => (props.isMobile ? '1rem' : '4rem')};
`;

const Title = styled.h1`
  color: #0b3d59;
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  margin: 0px 0px 50px 0px;
  text-align: center;
`;

const Label = styled.h3`
  font-size: 1rem;
`;

const ButtonContainer = styled.div`
  margin: 1em 0 0 0;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const SuccessMsg = styled.h3`
  font-size: 1.1rem;
`;

const ErrorMsg = styled.h3`
  margin: 0;
  font-size: 1rem;
  color: #EF4036;
`;

const ContactForm = () => {
  const isMobile = useMediaQuery('(max-width:870px)');

  const nameValue = useRef('');
  const emailValue =  useRef('');
  const queryValue = useRef('');

  const [showButton, setShowButton] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [showEntryValidation, setShowEntryValidation] = useState(false);
  const [showEmailValidation, setShowEmailValidation] = useState(false);

  const toggleButton = () => {
    setShowButton(!showButton);
    setShowSuccess(!showSuccess)
  };

  const handleSubmit = () => {
    var data = new FormData();

    setShowEntryValidation(false);
    setShowEmailValidation(false);

    var formFields = {
      "name": nameValue.current.value.trim(),
      "email": emailValue.current.value.trim(),
      "query": queryValue.current.value.trim()
    };

    if (formFields.name === '' || formFields.email === '' || formFields.queryValue === '') {
      setShowEntryValidation(true);
      return;
    }

    if ((/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formFields.email)) === false) {
      setShowEmailValidation(true);
      return;
    }

    setDisableButton(true);

    for (var key in formFields) {
      data.append(key, formFields[key]);
    }
    
    fetch('https://script.google.com/macros/s/AKfycbz36cFV5774rrtBb2mowvsdltA6kSFd_x9tvKuYIHyCHhtc-6tfz6AmiZE8Kh3jSsgP/exec', {
      method: 'POST',
      body: data
    }).then(res => {
     return res.json()
    }).then((data)=>{
      toggleButton()
    }).catch(err => console.log(err))
  }

  return (
    <MainContainer>
      <Container isMobile={isMobile}>
        <Title>Contact Form</Title>
        <Label>Name</Label>
        <TextField id="name" label="Enter your full name here" fullWidth inputRef={nameValue} />
        <Label>Email</Label>
        <TextField id="email" label="Enter a valid email id" fullWidth inputRef={emailValue} />
        <Label>Message or Query</Label>
        <TextField
          id="outlined-textarea"
          label="Enter your query in detail"
          rows={4}
          multiline
          fullWidth
          inputRef={queryValue}
        />
        {showButton && <ButtonContainer>
          <Button variant='contained' color='secondary' onClick={handleSubmit} disabled={disableButton}>Submit</Button>

          {showEntryValidation && <ErrorMsg className='entry-validation'>Fill all the fields</ErrorMsg>}

          {showEmailValidation && <ErrorMsg className='email-validation'>Use a valid email ID</ErrorMsg>}

        </ButtonContainer>}
        {showSuccess && <SuccessMsg>Query Sent Successfully! We'll get in touch with you soon!</SuccessMsg>}
      </Container>
    </MainContainer>
  );
};

export default ContactForm;
