import { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from "react-ga4";

import Banner from '../components/Banner/Banner';
import Testimonial from '../components/Testimonial/Testimonial';
import Caption from '../components/Caption/Caption';
import TestimonialsBG from '../assets/Banners/Testimonials.jpg';

const MainContainer = styled.div``;

const Testimonials = () => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Testimonials",
  });

  return (
    <MainContainer>
      <Banner
        backgroundImage={TestimonialsBG}
        category="About"
        title="Discover the experiences of our previous interns"
        subtitle="Testimonials from our outgoing interns"
      />
      <Testimonial />
      <Caption
        TitleText="Want to apply for internships abroad?"
        SubtitleText="Check out our students page for more details on the same."
        ButtonText="Students"
        ButtonLink="/students"
      />
    </MainContainer>
  );
};

export default Testimonials;
