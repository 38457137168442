import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';

const Container = styled.div`
  background: white;
  height: 270px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 10px;
  margin: 30px 0px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.02);
  }
  ${(props) => props.isMobile && `height: auto`}
`;

const Number = styled.h1`
  font-size: 5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  ${(props) =>
    props.isMobile &&
    `font-size:3rem;
  `}
`;

const Illustration = styled.img`
  height: 100%;
  width: 270px;
  object-fit: cover;
  ${(props) => props.isMobile && `display:none`}
`;

const ContentArea = styled.div`
  max-width: 27vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  ${(props) => props.isMobile && `max-width: 80%;padding:20px 10px;`}
`;

const Title = styled.h2`
  font-family: 'Futura Std Heavy';
  font-size: 1.125rem;
  margin: 0px;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  color: #7a7a7a;
  font-size: 0.875rem;
  margin: 0;
`;

const Card = ({ StepIllustration, StepTitle, Instructions, StepNo }) => {
  const isMobile = useMediaQuery('(max-width:870px)');

  return (
    <Container isMobile={isMobile}>
      <Number isMobile={isMobile}>{StepNo}</Number>
      <Illustration isMobile={isMobile} src={StepIllustration} />
      <ContentArea isMobile={isMobile}>
        <Title>{StepTitle}</Title>
        <Text>{Instructions}</Text>
      </ContentArea>
    </Container>
  );
};

export default Card;
