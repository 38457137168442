import styled from 'styled-components';
import { Fragment } from 'react';
import { useMediaQuery } from '@mui/material';

import RegularCard from './RegularCard';
// import PointsCard from './PointsCard';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0px;
`;

const Container = styled.div`
  width: 70vw;
  ${(props) => props.isMobile && `width: 90vw`}
`;

const Title = styled.h1`
  color: #0b3d59;
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  margin: 0px 0px 50px 0px;
  text-align: center;
`;

const BoldContent = styled.span`
  font-family: 'Futura Std Heavy';
`;

const HowItWorks = () => {
  const isMobile = useMediaQuery('(max-width:870px)');

  return (
    <MainContainer>
      <Container isMobile={isMobile}>
        <Title>How It Works</Title>
        <RegularCard
          BackgroundColor="#FFFFFF"
          TextColor="#0B3D59"
          ParagraphContent={[
            'In the increasingly competitive global market, the need to stand out from the crowd is more essential than ever before.',
            <Fragment>
              IAESTE internships offer the
              <BoldContent> unique opportunity </BoldContent>
              of pursuing a
              <BoldContent> paid, technical internship </BoldContent> while
              experiencing a different country and culture, and making friends
              with people from<BoldContent> all over the world</BoldContent>.
            </Fragment>,
          ]}
        />

        <RegularCard
          BackgroundColor="#0B3D59"
          TextColor="#FFFFFF"
          TitleContent="Membership"
          ParagraphContent={[
            'Members may view and apply for offers, as well as avail for other benefits specific to each Local Committe of IAESTE India. ',
          ]}
          PointContent={[
            [
              'Universities with Local Committees or Cooporating Institutions',
              'Contact the IAESTE team at your respective universities for further instructions on how to become a member',
            ],
            [
              'Outstation Membership',
              'Students not from universities with their own IAESTE teams can contact one of the Local Committees for instructions on applying for outstation membership',
            ],
          ]}
        />
        <RegularCard
          BackgroundColor="#FFFFFF"
          TextColor="#0B3D59"
          TitleContent="Proceedure"
          PointContent={[
            [
              'The best candidate for the opportunity is determined by a rigorous procedure of evaluation by a panel of experienced professionals. Your application will be based on your suitability for the offer.',
            ],
            [
              'If you are selected as the most suitable candidate, you are required to prepare an application that is sent to the employer university or corporate. At this point, the employer will evaluate your application and if accepted, your internship will be confirmed.',
            ],
          ]}
        />
        <RegularCard
          BackgroundColor="#0B3D59"
          TextColor="#FFFFFF"
          TitleContent="Reserved Offers"
          ParagraphContent={[
            'If you have already found an internship opportunity abroad, or are in the process of searching for one, you may convert your internship to an IAESTE internship and IAESTE will help you out with your VISA process and reception at your destination country.',
            'IAESTE India does not provide any scholarships or grants for any such offers.',
          ]}
        />
        <RegularCard
          BackgroundColor="#FFFFFF"
          TextColor="#0B3D59"
          TitleContent="Incoming Students"
          ParagraphContent={[
            'If you are foreign national, soon headed to India for an IAESTE internship, we are here to assist you. For any further queries, you can reach out to your Local Committee or mail us at incoming.exchange@iaeste.in',
          ]}
        />
      </Container>
    </MainContainer>
  );
};

export default HowItWorks;
