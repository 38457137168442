import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';

import role1 from '../../assets/Role as an Employer/1.png';
import role2 from '../../assets/Role as an Employer/2.png';
import role3 from '../../assets/Role as an Employer/3.png';
import role4 from '../../assets/Role as an Employer/4.png';

import RegularCard from './RegularCard';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0px;
  background-color: #0b3d59; ;
`;

const Container = styled.div`
  width: 70vw;
  padding: 50px 0;
  ${(props) => props.isMobile && `width: 90vw`};
`;

const Title = styled.h1`
  color: #fff;
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  margin: 0px 0px 50px 0px;
  text-align: center;
`;

const cardContent = [
  {
    roleDescription:
      "Pay a sufficient stipend to cover the intern's basic needs during their stay.",
    image: role1,
    alt: 'Employer Paying Intern Illustration',
  },
  {
    roleDescription:
      'Provide a safe working environment and proper supervision for training.',
    image: role2,
    alt: 'Safe Working Environment Illustration',
  },
  {
    roleDescription:
      'Ensure that the interns get high quality and relevant work experience.',
    image: role3,
    alt: 'High Quality and Relevant work Experience Illustration',
  },
  {
    roleDescription:
      "Send an evaluation of the intern's performance and your experience with IAESTE at the end of the internship.",
    image: role4,
    alt: 'Intern Performance Report Illustration',
  },
];

const RoleAsEmployer = () => {
  const isMobile = useMediaQuery('(max-width:870px)');
  return (
    <MainContainer>
      <Container isMobile={isMobile}>
        <Title>Your Role as an Employer</Title>
        {cardContent.map((item, key) => {
          return (
            <RegularCard
              RoleDescription={item.roleDescription}
              ImageContent={item.image}
              ImageAlt={item.alt}
              key={key}
              IsMobile={isMobile}
            />
          );
        })}
      </Container>
    </MainContainer>
  );
};

export default RoleAsEmployer;
