import styled from 'styled-components';

// Need to write for viewpoints lesser than 350px
const Card = styled.div`
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.15),
    0 4px 6px -2px rgba(0, 0, 0, 0.1);
  width: 350px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.02);
  }
`;

const CardImage = styled.img`
  height: 227.27px;
  width: 100%;
  object-fit: cover;
`;

const CardText = styled.div`
  width: 100%;
  text-align: center;
`;

const CardHeader = styled.h1`
  font-family: 'Futura Std Medium';
  font-size: 1rem;
  padding: 0px 10px;
`;

const CommitteesCard = ({ BackgroundImage, CommitteesName }) => {
  return (
    <Card>
      <CardImage src={BackgroundImage}></CardImage>
      <CardText>
        <CardHeader>{CommitteesName}</CardHeader>
      </CardText>
    </Card>
  );
};

export default CommitteesCard;
