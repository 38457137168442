import styled from 'styled-components';

import FaqBox from './FaqBox';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0px;
`;

const Container = styled.div`
  width: 90vw;
`;

const Title = styled.h1`
  color: #0b3d59;
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  margin: 0px 0px 50px 0px;
  text-align: center;
`;

const Faq = ({ FaqArray }) => {
  return (
    <MainContainer>
      <Container>
        <Title>Frequently Asked Questions (FAQs)</Title>
        {FaqArray.map((item, index) => {
          return (
            <FaqBox Query={item.Query} Response={item.Response} key={index} />
          );
        })}
      </Container>
    </MainContainer>
  );
};

export default Faq;
