import styled from 'styled-components';
import Down from '../../assets/Icons/down.svg';

const Label = styled.label`
  margin: 0px 0px 50px 0px;
`;

const Select = styled.select`
  height: 50px;
  width: min(250px, 75vw);
  padding: 0 20px;
  border: none;
  font-family: 'Futura Std Medium';
  font-size: 0.875rem;
  color: #a8a8a8;

  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  appearance: none;

  padding-right: 10px;
  background-image: url(${Down});
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  background-size: 20px;

  &:focus {
    outline: none;
  }
`;

const Option = styled.option`
  background: white;
  color: green;
`;

const DropDrown = ({ label, value, options, onChange }) => {
  return (
    <label>
      <Select value={value} onChange={onChange}>
        {options.map((option, index) => (
          <Option value={option.value} key={index}>
            {option.label}
          </Option>
        ))}
      </Select>
    </label>
  );
};

export default DropDrown;
