import styled, { css, keyframes } from 'styled-components';

const MainContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 20px 0 rgba(13, 51, 32, 0.1);
  height: 232px;
  display: flex;
  transition: transform 0.3s;
  margin: 50px 0;

  &:hover {
    transform: scale(1.02);
  }

  ${(props) => props.isRight && 'justify-content: space-between'};
`;

const CardTitle = styled.h1`
  font-family: 'Futura Std Heavy';
  font-size: 1.125rem;
  color: #0b3d59;
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
  ${(props) => props.isMobile && `color:#fff`}
`;

const CardContent = styled.p`
  font-size: 0.875rem;
  color: #7a7a7a;
  margin: 0;
  padding: 0;
  ${(props) => props.isMobile && `color:#fff`}
`;

const fadeInBottom = keyframes`
from {
    opacity: 0;
    transform: translateY(100%);
  }

to { opacity: 1 }`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  ${(props) =>
    props.isMobile &&
    css`
      position: absolute;
      background-color: rgba(11, 61, 89, 0.6);
      height: 232px;
      width: 90vw;
      border-radius: 8px;
    `};

  /* animation-name: ${fadeInBottom};
  animation-duration: 3s;
  animation-fill-mode: both; */
`;

const Illustration = styled.img`
  height: 100%;
  width: ${(props) => (props.isMobile ? '100%' : '240px')};
  object-fit: cover;
  object-position: center;
  border-radius: 8px 0 0 8px;
  ${(props) => props.isRight && `border-radius: 0 8px 8px 0`};
  ${(props) => props.isMobile && `border-radius: 8px`};
`;

const RegularCard = ({
  TitleContent,
  ParaContent,
  ImageContent,
  ImageAlt,
  Direction,
  IsMobile,
}) => {
  const isRight = Direction === 'left' ? false : true;

  return (
    <MainContainer isRight={isRight}>
      {Direction === 'left' && (
        <Illustration
          src={ImageContent}
          alt={ImageAlt}
          isRight={isRight}
          isMobile={IsMobile}
        />
      )}
      <ContentContainer isMobile={IsMobile}>
        <CardTitle isMobile={IsMobile}>{TitleContent}</CardTitle>
        <CardContent isMobile={IsMobile}>{ParaContent}</CardContent>
      </ContentContainer>
      {Direction === 'right' && (
        <Illustration
          src={ImageContent}
          alt={ImageAlt}
          isRight={isRight}
          isMobile={IsMobile}
        />
      )}
    </MainContainer>
  );
};

export default RegularCard;
