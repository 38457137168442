import styled from 'styled-components';
import Search from '../../../assets/Icons/search.svg';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0px;
`;

const Container = styled.div`
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  color: #0b3d59;
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  margin: 0px 0px 50px 0px;
  text-align: center;
`;

const SubTitle = styled.p`
  margin: 0;
  font-family: 'Futura Std Heavy';
  font-size: 1.125rem;
  color: #7a7a7a;
  text-align: center;
`;

const SearchField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 50px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  margin-top: 50px;
`;

const SearchIconContainer = styled.div`
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchIcon = styled.img`
  height: 30px;
  width: 30px;
`;

const SearchInput = styled.input`
  height: 100%;
  width: min(250px, 75vw);
  padding: 0 20px;
  border: none;
  border-radius: 10px;
  font-family: 'Futura Std Medium';
  font-size: 0.875rem;
  color: #a8a8a8;
  &:focus {
    outline: none;
  }
`;

const VisaSearch = () => {
  return (
    <MainContainer>
      <Container>
        <Title>Visa Info Countrywise</Title>
        <SubTitle>
          Select a country below to get a brief info on Visa process
        </SubTitle>
        <SearchField>
          <SearchIconContainer>
            <SearchIcon src={Search} />
          </SearchIconContainer>
          <SearchInput placeholder="Search country for visa info" />
        </SearchField>
      </Container>
    </MainContainer>
  );
};

export default VisaSearch;
