import styled from 'styled-components';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Divider, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

import Facebook from '../../../assets/Icons/facebook.svg';
import Instagram from '../../../assets/Icons/instagram.svg';
import Linkedin from '../../../assets/Icons/linkedin.svg';
import Twitter from '../../../assets/Icons/twitter.svg';

const ListContainer = styled.div`
  margin: 0;
  padding: 20px;

  display: flex;
  height: calc(100vh - 72px);
  flex-direction: column;
  width: 100%;
  position: fixed;
  top: 72px;
  left: 0;
  background-color: white;
  ${(props) =>
    props.isHidden &&
    `
    display: none;
    `}
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  padding: 10px 24px;
`;

const MultiListParentItem = styled(ListItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
`;

const ListText = styled.span`
  color: #0b3d59;
`;

const NavigateNext = styled(NavigateNextIcon)`
  color: #0b3d59;
`;

const SocialIcons = styled.div`
  padding: 10px 0px;
  display: flex;
  justify-content: center;
`;

const Icon = styled.img`
  height: 1.5rem;
  margin: 0px ${(props) => props.margin || 0};
  margin-left: ${(props) => props.marginLeft || 0};
  margin-right: ${(props) => props.marginRight};
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const MobileNavLinks = ({ isHidden, handleMultiClick, handleClick }) => {
  return (
    <ListContainer isHidden={isHidden}>
      <List>
        <NavLink to="/" onClick={() => handleClick(-1)}>
          <ListItem>
            <ListText>Home</ListText>
          </ListItem>
        </NavLink>

        <MultiListParentItem onClick={() => handleMultiClick(0)}>
          <ListText>About</ListText>
          <NavigateNext />
        </MultiListParentItem>
        <MultiListParentItem onClick={() => handleMultiClick(1)}>
          <ListText>Students</ListText>
          <NavigateNext />
        </MultiListParentItem>
        <MultiListParentItem onClick={() => handleMultiClick(2)}>
          <ListText>Employers</ListText>
          <NavigateNext />
        </MultiListParentItem>

        <ExternalLink href="https://magazine.iaeste.in" target='_blank' onClick={() => handleClick(-1)}>
          <ListItem>
            <ListText>Magazine</ListText>
          </ListItem>
        </ExternalLink>

        <NavLink to="/contact" onClick={() => handleClick(-1)}>
          <ListItem>
            <ListText>Contact us</ListText>
          </ListItem>
        </NavLink>
      </List>

      <Divider sx={{ margin: '10px 0 0 0', color: '#0B3D59' }} />
      <SocialIcons>
        <IconButton
          aria-label="IAESTE India Facebook"
          href="https://www.facebook.com/iaesteindia/"
          target="_blank"
        >
          <Icon src={Facebook} />
        </IconButton>
        <IconButton
          aria-label="IAESTE India Instagram"
          href="https://www.instagram.com/iaeste_india/?hl=en"
          target="_blank"
        >
          <Icon src={Instagram} />
        </IconButton>
        <IconButton
          aria-label="IAESTE India Linkedin"
          href="https://www.linkedin.com/company/iaeste-india/"
          target="_blank"
        >
          <Icon src={Linkedin} />
        </IconButton>
        <IconButton
          aria-label="IAESTE India Twitter"
          href="https://twitter.com/iaeste_india"
          target="_blank"
        >
          <Icon src={Twitter} />
        </IconButton>
      </SocialIcons>
    </ListContainer>
  );
};

export default MobileNavLinks;

// <List>
//   <ListItem scrollY={scrollY}>
//     <ListText>Home</ListText>
//   </ListItem>
//   <MultiListParentItem scrollY={scrollY}>
//     <MultiListHeader>
//       <ListText>About</ListText>
//       <DropDownIcon />
//     </MultiListHeader>
//     <MultiListContent>
//       <MultiListItem>Testimonials</MultiListItem>
//       <Divider />
//       <MultiListItem>Committees</MultiListItem>
//       <Divider />
//       <MultiListItem>Board</MultiListItem>
//       <Divider />
//       <MultiListItem>IAESTE A.S.B.L</MultiListItem>
//     </MultiListContent>
//   </MultiListParentItem>
//   <MultiListParentItem scrollY={scrollY}>
//     <MultiListHeader>
//       <ListText>Students</ListText>
//       <DropDownIcon />
//     </MultiListHeader>
//     <MultiListContent>
//       <MultiListItem>How it works</MultiListItem>
//       <Divider />
//       <MultiListItem>FAQs</MultiListItem>
//       <Divider />
//       <MultiListItem>Events</MultiListItem>
//       <Divider />
//       <MultiListItem>Internships</MultiListItem>
//       <Divider />
//       <MultiListItem>Visa Guidelines</MultiListItem>
//     </MultiListContent>
//   </MultiListParentItem>
//   <MultiListParentItem scrollY={scrollY}>
//     <MultiListHeader>
//       <ListText>Employers</ListText>
//       <DropDownIcon />
//     </MultiListHeader>
//     <MultiListContent>
//       <MultiListItem>How it works</MultiListItem>
//       <Divider />
//       <MultiListItem>FAQs</MultiListItem>
//       <Divider />
//       <MultiListItem>Partner With Us</MultiListItem>
//     </MultiListContent>
//   </MultiListParentItem>
//   <ListItem scrollY={scrollY}>
//     <ListText>Contact us</ListText>
//   </ListItem>
// </List>
