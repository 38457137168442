import styled from 'styled-components';

import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';

const Container = styled.div`
  background-color: white;
  padding: 10px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  margin: 20px 0px;
  color: #0b3d59;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Question = styled.h1`
  margin: 0;
  font-family: 'Futura Std Book';
  font-size: 1.3125rem;
`;

const AddButton = styled(AddIcon)`
  && {
    transform: rotate(0deg);
    transition: 0.3s ease-in-out;
    width: 3rem;
    ${(props) => props.$ishidden && `transform: rotate(135deg);`};
  }
`;

const Bottom = styled.div`
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  color: var(--color-text-muted);
  transform: translateX(16px);
  transition: max-height 0.5s ease, opacity 0.5s, transform 0.5s;
  border-top: 2px solid #1b75bb;

  ${(props) =>
    props.$ishidden &&
    `opacity: 1;
    transform: translateX(0px);
    max-height: 100vh;`};
`;

const Answer = styled.p`
  margin: 0;
  font-family: 'Futura Std Book';
  font-size: 1.125rem;
  padding: 20px 0px;
  color: #7a7a7a;
`;

const FaqBox = ({ Query, Response }) => {
  const [isHidden, setIsHidden] = useState(null);
  return (
    <Container>
      <Top
        onClick={() => {
          setIsHidden(!isHidden);
        }}
      >
        <Question>{Query}</Question>
        <AddButton $ishidden={isHidden} />
      </Top>
      <Bottom $ishidden={isHidden}>
        <Answer>{Response}</Answer>
      </Bottom>
    </Container>
  );
};

export default FaqBox;
