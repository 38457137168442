import { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from "react-ga4";

import Banner from '../components/Banner/Banner';
import ContactForm from '../components/ContactForm/ContactForm';
import Caption from '../components/Caption/Caption';
import ContactBG from '../assets/Banners/ContactUs.jpeg';

const MainContainer = styled.div``;

const Contact = () => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Contact",
  });

  return (
    <MainContainer>
      <Banner
        backgroundImage={ContactBG}
        category="Contact"
        title="Want to reach out to us?"
        subtitle="Contact us for more information"
      />
      <ContactForm />
      <Caption
        TitleText="Are you a student or an employer?"
        SubtitleText="Click on the respective button to be redirected to the necessary page."
        ButtonText="Student"
        ButtonLink="/students"
        ButtonText2="Employers"
        ButtonLink2="/employers"
      />
    </MainContainer>
  );
};

export default Contact;
