import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';


import outgoing0 from '../../assets/Testimonial Images/outgoing0.png';
import outgoing1 from '../../assets/Testimonial Images/outgoing1.png';
import outgoing2 from '../../assets/Testimonial Images/outgoing2.png';
import outgoing3 from '../../assets/Testimonial Images/outgoing3.png';
import outgoing4 from '../../assets/Testimonial Images/outgoing4.png';
import outgoing5 from '../../assets/Testimonial Images/outgoing5.png';
import outgoing6 from '../../assets/Testimonial Images/outgoing6.png';
import outgoing7 from '../../assets/Testimonial Images/outgoing7.png';
import outgoing8 from '../../assets/Testimonial Images/outgoing8.png';
import outgoing9 from '../../assets/Testimonial Images/outgoing9.png';
import outgoing10 from '../../assets/Testimonial Images/outgoing10.png';
import outgoing11 from '../../assets/Testimonial Images/outgoing11.png';
import outgoing12 from '../../assets/Testimonial Images/outgoing12.png';
import outgoing13 from '../../assets/Testimonial Images/outgoing13.png';
import outgoing14 from '../../assets/Testimonial Images/outgoing14.png';
import outgoing15 from '../../assets/Testimonial Images/outgoing15.png';
import outgoing16 from '../../assets/Testimonial Images/outgoing16.png';
import outgoing17 from '../../assets/Testimonial Images/outgoing17.png';
import incoming0 from '../../assets/Testimonial Images/incoming0.png';
import incoming1 from '../../assets/Testimonial Images/incoming1.png';
import incoming2 from '../../assets/Testimonial Images/incoming2.png';
import incoming3 from '../../assets/Testimonial Images/incoming3.png';
import incoming4 from '../../assets/Testimonial Images/incoming4.png';
import incoming5 from '../../assets/Testimonial Images/incoming5.png';
import incoming6 from '../../assets/Testimonial Images/incoming6.png';
import incoming7 from '../../assets/Testimonial Images/incoming7.png';  
import incoming8 from '../../assets/Testimonial Images/incoming8.png';
import incoming9 from '../../assets/Testimonial Images/incoming9.png';
import incoming10 from '../../assets/Testimonial Images/incoming10.png';
import incoming11 from '../../assets/Testimonial Images/incoming11.png';
import incoming12 from '../../assets/Testimonial Images/incoming12.png';
import incoming13 from '../../assets/Testimonial Images/incoming13.png';

import Card from './Card';
import DropDrown from './DropDown';
import { css } from 'styled-components';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0px;
`;

const Container = styled.div`
  width: 70vw;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.isMobile && `width: 95vw`};
`;

const Title = styled.h1`
  color: #0b3d59;
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  margin: 0px 0px 50px 0px;
  padding: 0;
  text-align: center;
`;

const SubTitle = styled.h2`
  font-family: 'Futura Std Heavy';
  font-size: 1.3125rem;
  color: #7a7a7a;
  margin: 0px 0px 50px 0px;
  padding: 0;
`;

const MediaContainer = styled.div`
  display: flex;
`;

const MediaType = styled.button`
  background: #ffffff;
  border: 1px solid #1b75bb;
  border-radius: 14.5px;
  font-family: 'Futura Std Heavy';
  font-size: 0.875rem;
  color: #1b75bb;
  text-align: center;

  margin: 50px 10px;
  width: 117px;
  height: 29px;

  ${(props) =>
    props.isColored &&
    css`
      background-color: #1b75bb;
      color: #fff;
    `}

  ${(props) =>
    props.disabled &&
    css`
      background-color: #cccccc;
      color: #666666;
      border: 1px solid #666666;
    `}
`;

const MediaText = styled.h3`
  padding: 0;
  margin: 0;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 2rem;
  column-gap: 2rem;
`;

const cardContent = {
  outgoing: {
    Story: [
      {
        internImage: outgoing15,
        internName: 'Anmol Verma',
        country: 'Austria',
        experience: "Deeply appreciative of IAESTE, I seized an incredible chance to serve as a junior software developer in Austria, a role that enriched the company's accomplishments. My focus centered on pivotal work overhauling the software's API. This experience immersed me in software development, offering real-world practice. Beyond refining my technical aptitude, the internship fostered teamwork, problem-solving, and adept communication. IAESTE's pivotal support spanned securing the internship to aiding my cultural adaptation, culminating in a transformative journey.",
        employer: 'Abis-Software Entwicklung'
      },
      {
        internImage: outgoing8,
        internName: 'Narmadha Devi',
        country: 'PSI',
        experience: "Discovering the internship opportunity during AGC offers, I applied for several positions and secured a spot with PSI, Switzerland. Immersed in a team working on the ITER fusion reactor for 'clean energy' in France, I gained hands-on experience, improving technical abilities and teamwork skills. Exploring Switzerland and Germany added a cultural dimension. Overall, this internship significantly contributed to my growth as a research student, providing valuable knowledge, connections, and skills. Grateful for the experience, I am confident it forms a robust foundation for my future endeavors.",
        employer: 'PSI, Switzerland'
      },
      {
        internImage: outgoing14,
        internName: 'Krishna Kishore',
        country: 'Tunisia',
        experience: "Embarking on a Tunisia internship was life-changing. Partnering with a Swedish colleague, we crafted an innovative ML e-commerce system, backed by a supportive supervisor. The diverse group of 30 global interns expanded my cultural horizons, forming lasting bonds. IAESTE Tunisia orchestrated enriching events, notably the South Trip to iconic sites like Kairouan's mosque and Sahara's Ong Jmal. From local cuisine joys, like Harissa, to the serene Thé à la Menthe moments, I'm captivated. This journey cultivated skills, cultural adeptness, and cherished memories, encapsulating a transformative experience within Tunisia's vibrant tapestry.",
        employer: 'TEK-UP University'
      },
      {	
        internImage: outgoing5,
        internName: 'Jayant Sharma',
        country: 'Germany',
        experience: 'I interned at the University of Bonn in Germany during the summer of 2023, and I am immensely grateful to IAESTE LC Manipal for providing me with this opportunity. The entire process, from applying to receiving the offer of my choice, obtaining the necessary documents on time, and the warm reception by LC Bonn, was seamless and commendable. I have always admired the research environment in Germany, and securing a DAAD-funded offer through IAESTE has been a breakthrough for my research career. I worked on a dataset from CERN Geneva, had the chance to contribute to a research team dedicated to a specific cause, and explored various European cities on weekends.',
        employer: 'University of Bonn'
      },
      {
        internImage: outgoing3,
        internName: 'Pooja Mishra',
        country: 'Slovakia',
        experience: "IAESTE's assistance, especially from Viraj, met my expectations. Zapadoslovenska Energetika's team, led by Tomas Skornak, was professional and accommodating. Juraj Hudcovsky recognized my work's value, offering a 6-month non-IAESTE internship. IAESTE Slovakia's team, including Andrea and Simona Hovoncikova, was friendly and supportive. Special thanks to Marek Rybarik, Romana, and Simona Propokova for going beyond in the visa process. Language barriers existed, but overall, the experience was positive. Best wishes to IAESTE in the future.",
        employer: 'Zapadoslovenska energetika, a.s.'
      },
      {	
        internImage: outgoing9,
        internName: 'Sanjai P',
        country: 'UAE',
        experience: 'Securing an internship at the University of Sharjah, UAE, was a life-changing experience. The opportunity provided valuable technical exposure through research projects and paper publications. Beyond technical skills, I cultivated essential social skills, interacting with diverse IAESTE interns from Brazil to Japan. Learning about their cultures, languages, and food habits broadened my perspective. Traveling and exploring the UAE with fellow IAESTE interns added to the richness of the experience. Grateful to IAESTE for this golden opportunity, I appreciate the profound impact it had on my professional and personal growth.',
        employer: 'University of Sharjah'
      },
      {
        internImage: outgoing11,
        internName: 'Sparsh Rawat',
        country: 'Czech Republic',
        experience: 'Embarking on my internship in the Czech Republic wa a summer highlight, etching unforgettable memories. The newfound friendships and mesmerizing vistas made it truly special. Heartfelt appreciation to IAESTE LC MUJ for making this experience possible. Beyond the joy, the internship enriched me with profound learning, altering my perspective as a student. The professional insights gained and challenges overcome have significantly shaped my journey. As I reflect on those transformative weeks, I am immensely grateful for the opportunity, the people, and the lessons that have forever left their mark on my life.',
        employer: 'Czech Technical University, Prague'
      },
      {
        internImage: outgoing13,
        internName: 'Manas Tripathi',
        country: 'Germany',
        experience: "Embarking on my IAESTE internship in Germany, guided by Professor Hoffmann, felt like unlocking a vault of progress through a door of opportunities. Beyond professional growth, it immersed me in a dream-fulfilling, obstacle-conquering society. IAESTE became my international launchpad, fostering cultural exchange and lifelong friendships. Under Professor Hoffmann's mentorship, I navigated new intellectual terrain. This journey equips me with cross-cultural strength and readiness. Thanks to IAESTE and Prof. Hoffmann, my story now embodies an uncharted map of promise and aspiration.",
        employer: 'University of Applied Sciences Osnabrueck'
      },
      {
        internImage: outgoing4,
        internName: 'Parshva Mody',
        country: 'Germany',
        experience: "When I joined college, 'Paid Internship Abroad' caught my attention amid club recruitments. It wasn't a hoax! LC Manipal eased the lengthy visa process, and LC Kiel arranged accommodation, ensuring a convenient living experience. The technical internship in Germany was exceptional, thanks to Dr. Slawig and Niko Schmidt. Adapting to a new environment was tough, but the overall experience, including cultural immersion and personal growth, made it a highly successful journey.",
        employer: 'Christian-Albrechts-University Kiel'
      },
      {
        internImage: outgoing16,
        internName: 'Priyansh Singh',
        country: 'Iran',
        experience: "I'm thrilled about my inaugural internship at the Iran University of Science and Technology under IAESTE Iran. As an assistant researcher, I contributed to developing vehicle headlight sensors using MATLAB, enhancing my technical skills and time management. Thanks to IAESTE, I gained not only professional connections but also insights into collaborative project work. The experience emphasized teamwork complexities and soft skill development, serving as a cultural exchange journey. This internship was a holistic learning opportunity, fostering both professional and personal growth in just six weeks. Grateful for the invaluable experience.",
        employer: 'Iran University of Science and Technology'
      },
      {
        internImage: outgoing10,
        internName: 'Joshua Kachhap',
        country: 'Indonesia',
        experience: 'I fulfilled my dream of visiting Indonesia, embarking on a two-month solo journey that proved transformative. The experience went beyond exploring diverse cuisines and making lifelong connections. The core of my visit was an internship involving a CNC Laser machine. Reversing engineering and dismantling the machine, I utilized SolidWorks for part design, enhancing my practical engineering skills. This immersive exposure not only reinforced classroom concepts but also enriched my engineering knowledge. Indonesia left an indelible mark on me, with countless memories that surpass the limitations of words.',
        employer: 'Universitas Atma Jaya Yogyakarta'
      },
      {
        internImage: outgoing17,
        internName: 'Aditya Shekhawat',
        country: 'Czech Republic',
        experience: "Embarking on an internship in the culturally rich Czech Republic was an incredible opportunity. IAESTE LC Zlin's warm welcome and seamless integration into the local culture and work environment made my experience memorable. The team's exceptional organization and attention to detail facilitated a smooth transition into both professional and social aspects of the internship. My heartfelt gratitude to IAESTE LC JECRC and IAESTE LC Zlin teams for their unwavering dedication. This internship not only enriched my professional skills but also gifted me cherished memories and lifelong friendships.",
        employer: 'Tomas Bata University'
      },
      {
        internImage: outgoing2,
        internName: 'Sachin D',
        country: 'Poland',
        experience: 'My internship focused on vibration analysis of footbridges, with my instructor being exceptionally kind and patient. His care and consideration made my adjustment smooth. Describing my stay in Poland, it was some of the best days of my life. Krakow surpassed my expectations, with warm and accepting people. The beautiful evenings and walks with friends are what I miss the most about Poland. It turned out to be an amazing destination with welcoming people and breathtaking views.',
        employer: 'Krakow University'
      },
      {
        internImage: outgoing7,
        internName: 'Shourya Sharma',
        country: 'Peru',
        experience: "Interning at Neurometrics feels like a rapid acceleration from zero to one hundred in just a few months. I've absorbed more knowledge about the field than I could have imagined, only scratching the surface. This internship breaks the mold, offering more than just meetings and tasks. I've met incredible people, learned from experts, and applied SEO, digital marketing, and social media lessons practically. The supportive team maintained close contact, making my experience more than worthwhile. I'm forever grateful for the mentorship and lessons received, as my time at Neurometrics has been truly invaluable.",
        employer: 'Neurometrics'
      },
      {
        internImage: outgoing6,
        internName: 'Shaun Varghese',
        country: 'Jordan',
        experience: 'My internship journey began with the rollout of IAESTE AGC offers. Shortlisted for an opportunity in Jordan, I immersed myself in a dynamic and collaborative environment, fostering both personal and professional growth. Working with a talented team challenged and expanded my programming skills on real-world projects. I felt supported and valued, with genuine appreciation for my contributions. In conclusion, my internship in Jordan was enriching, significantly contributing to my growth as a programmer. I am confident that the lessons learned will serve as a strong foundation for my future endeavors.',
        employer: 'ProgressSort Corporation'
      },
      {
        internImage: outgoing1,
        internName: 'Sanskruti Choudhari',
        country: 'Germany',
        experience: 'During my summer internship at FAU Erlangen, I immersed myself in Metal-Organic Frameworks (MOFs), focusing on pseudomorphic transformations. Collaborating with experienced colleagues in cutting-edge facilities, I contributed to rigorous research methodologies. The collaborative environment fostered intellectual exchange, deepening my understanding of materials science. Reflecting on the internship, it was a transformative journey, nurturing both professional and personal growth. It expanded my horizons in materials science, crystalline structures, and cultural awareness, offering invaluable insights for my future endeavors.',
        employer: 'FAU Erlangen-Nürnberg'
      },
      {
        internImage: outgoing0,
        internName: 'Shashvat Tiwari',
        country: 'Oman',
        experience: "I'm immensely thankful to IAESTE LC Manipal for their unwavering support during my internship in Oman. At Omantel, I experienced a dynamic work environment, gaining hands-on experience in network audits and cutting-edge technology. IAESTE LC Manipal's continuous support and organized events created a sense of camaraderie, making me feel at home. This transformative experience not only enhanced my technical skills but also broadened my cultural understanding. I am profoundly grateful to IAESTE for opening doors to possibilities and being a guiding light for young professionals like me.",
        employer: 'Omantel'
      },
      {
        internImage: outgoing12,
        internName: 'Sarthak Chugh',
        country: 'Tunisia',
        experience: "Delighted to share the completion of my enriching one-month electronics engineering internship in Tunisia. Collaborating with a skilled team heightened my technical prowess and comprehension of engineering concepts. Beyond professional growth, I immersed myself in Tunisia's beauty from vibrant markets in Tunis to captivating beaches in Sousse. Forming deep friendships with global peers, savoring local cuisine, and embracing rich culture were cherished highlights. Grateful to IAESTE India LC MUJ and IAESTE TUNISIA for this exceptional opportunity. The lessons, memories, and skills acquired are invaluable for my future pursuits.",
        employer: 'Electronic Design Service SARL'
      }
    ],
    Video: [{}],
  },
  incoming: {
    Story: [
      {
        internImage: incoming11,
        internName: 'Friedrich Dang',
        country: 'Germany',
        experience: "Thank you all for the great time and cheerful memories. You've been all inspiring personalities to me. Being part of this international family was a real pleasure and broadened my horizon by the size of two Indias. Auf ein schones Wiedersehen! Take care! ",
        employer: 'Manipal University, Jaipur'
      },
      {
        internImage: incoming6,
        internName: 'Daniel Kurzen',
        country: 'Switzerland',
        experience: "Hi I am Daniel and in my internship I was dedicated to analyzing Tamil Nadu's East Coast using satellite images for zone classification and mangrove identification. Opting for Karunya was a deliberate choice, driven by its dedication to conservation through academic pursuits. My passion for exploring India's diverse landscapes and cultures has materialized through extensive travel. Fond memories of camaraderie formed during stays in the guest house linger, and I wholeheartedly recommend IAESTE for its unique blend of skill acquisition, travel opportunities, and cultural immersion across this captivating country.",
        employer: 'Karunya Institute of Science and Technology, Coimbatore'
      },
      {
        internImage: incoming2,
        internName: 'MD. Moinur Rahman',
        country: 'Bangladesh',
        experience: 'Embarking on the journey of learning nanotechnology has been both enlightening and rewarding as I delved into the fascinating world of nanoscale science and technology. The friendly and helpful behavior of those guiding me through this exploration has been instrumental in making the learning process enjoyable and effective. The commitment to fostering a friendly and helpful atmosphere has undoubtedly contributed to the overall success of this nanotechnology learning experience. I am eager to carry forward the knowledge gained and apply it to future endeavors, grateful for the guidance and encouragement received throughout this educational exploration.',
        employer: 'Sikkim Manipal University, Sikkim'
      },
      {
        internImage: incoming0,
        internName: 'Nirmal Kumar Srinivasan',
        country: 'Sweden',
        experience: 'My internship experience has been nothing short of excellent, providing me with a wealth of knowledge in a broad spectrum of fields, including material science, Finite Element Analysis, and design. Being involved in teamwork and group discussions has proven to be immensely beneficial in my learning process. I am grateful for the opportunity to have been part of such a comprehensive and enlightening internship. The skills acquired, will undoubtedly serve as a solid foundation for my future endeavors. I enthusiastically recommend this internship to any prospective individual seeking a well-rounded and challenging learning experience.',
        employer: 'Sikkim Manipal University, Sikkim'
      },
      {
        internImage: incoming9,
        internName: 'Suwapat Thongyoun',
        country: 'Thailand',
        experience: "During my stay in India, I forged meaningful connections with international friends. The hospitality extended by IAESTE MUJ was truly warm, and their constant support during challenging times was invaluable. India's rich tapestry of cultures and breathtaking landscapes left a lasting impression. Experiencing the Taj Mahal during a sunrise visit was incredibly enchanting. Dr. Santosh Patil's supervision and support were crucial, offering valuable advice and assistance when needed. Overall, my time in India stands as the most enjoyable and memorable experience of my life.",
        employer: 'Manipal University, Jaipur'
      },
      {
        internImage: incoming10,
        internName: 'Marlene Elisabeth Metz',
        country: 'Germany',
        experience: 'A few hours ago I finally arrived home after 8 exciting weeks in India. Nevertheless, I will look back at the time with a warm heart, and miss you all so badly already. Thank you so much for the awesome times, trips, games, and evenings, and your kindness and infinite support. I am happy to be home now and will tell all our stories with excitement to my family and friends that I can meet in the short time I have before my next adventure. I will never forget you guys, hope to see you all again eventually! Thanks for everything, and lots of love.',
        employer: 'Manipal University, Jaipur'
      },
      {
        internImage: incoming5,
        internName: 'Ahmed Mohammed',
        country: 'Austria',
        experience: "I'm Ahmed, an Egyptian AI master's student in Austria, immersed in motor imagery classification. Karunya's internship has proven wise, offering comfortable accommodation shared with a friendly Czech roommate. Diverse food options have added flavor to my experience. Exploring Bangalore, Ooty, Pondicherry, and more has been delightful. In retrospect, I highly recommend IAESTE for aspiring students. It's been enriching professionally and culturally, and I'm grateful for the opportunities it provided.",
        employer: 'Karunya Institute of Science and Technology, Coimbatore'
      },
      {
        internImage: incoming8,
        internName: 'Petr Stastny',
        country: 'Czech Republic',
        experience: "Hello, I'm Petr Stastny from the Czech Republic. My Karunya University internship immersed me in metal 3D printing, optimizing processes and designing support structures. Choosing Karunya fulfilled my goal of interning in Asia, proving to be rewarding. The provided accommodation enhanced my positive experience, and building connections with fellow interns was a highlight. India's diverse climate, majestic landscapes, rich culture, and vibrant cities left a lasting impression. IAESTE crafted an exceptional journey, seamlessly blending professional growth with cultural immersion.",
        employer: 'Karunya Institute of Science and Technology, Coimbatore'
      },
      {
        internImage: incoming4,
        internName: 'Sana Sakly',
        country: 'Tunisia',
        experience: 'In my adventurous stay in India, I explored the stunning natural beauty of the South, visiting places like Mangalore, Kerkala, Brahmavar, Goa, Coorg, Mysore, Bangalore, Mumbai, and Pune. Each locale had a unique vibe, and making friends everywhere added to the experience. My internship at the MSAP Manipal School of Architecture and Planning exceeded expectations. The vibrant student hub of Manipal offered daily fun, and working with the students on university designs was incredible. Thanks to IAESTE LC Manipal, my experience was a perfect blend of professionalism and welcoming vibes, teaching me valuable lessons in diversity and fresh perspectives.',
        employer: 'Manipal University, Manipal'
      },
      {
        internImage: incoming12,
        internName: 'Aaron Goff',
        country: 'United Kingdom',
        experience: 'Now I am home I just wanted to let you know had the best time during the eight weeks I spent in India and enjoyed a summer I will remember forever. I would like to take this opportunity to thank all the coordinators who I felt did an incredible job at looking after me throughout my stay, through which we shared amazing experiences. Please share this message with all the coordinators who looked after me as I would like them to know how grateful I am for all their help and support. It is a shame to have left India but I hope to see many of you again at some point in the future! ',
        employer: 'Manipal University, Jaipur'
      },
      {
        internImage: incoming13,
        internName: 'Mahsa Nasiri',
        country: 'Iran',
        experience: 'The sentiment, "You may find yourself shedding tears on the first day of your arrival in India, and you will also experience the same emotions on the day of your departure," resonates deeply with me. Thanks to the cherished memories created, I can confidently say it was the best trip of my life. Guided by my supervisor, the technical experience gained will undoubtedly shape my future career. I\'m immensely grateful for IAESTE LC JECRC, consistently ensuring the best possible stay. Doing this internship stands out as one of the best decisions I\'ve made. I hope to return to my second home someday.',
        employer: 'JECRC University, Jaipur'
      },
      {
        internImage: incoming7,
        internName: 'Fatemeh Rahmanifiroozjaee',
        country: 'Iran',
        experience: 'I am Fatemeh from Iran, currently interning under Dr. Sneha Gautam at Karunya. Investigating the influence of aerosols, precipitation, and humidity on lightning phenomena, my research is both challenging and fulfilling. Surprisingly, the cultural resonance between India and Iran has facilitated a smooth transition. Late-night cooking sessions with fellow interns have added a delightful social dimension. To prospective interns, I recommend approaching this opportunity with an open mind as it has been a rewarding journey, both academically and culturally.',
        employer: 'Karunya Institute of Science and Technology, Coimbatore'
      },
      {
        internImage: incoming3,
        internName: 'Ana Baborski',
        country: 'Germany',
        experience: "Organizing trips to nearby places like Udupi's beaches, Hampi, and Coorg with friends added a delightful touch to my stay in Manipal. Despite working extensively on content for papers during the internship, I enjoyed the freedom to explore different aspects, including the unfamiliar territory of generating biopolymers in the lab. My supervisor, Sonali, was instrumental, guiding me through the process and answering my questions. Despite not being able to test antimicrobial activities, the overall experience in India, exploring diverse cultures and lifestyles, supports personal growth in every way. I highly recommend such an enriching experience.",
        employer: 'Manipal University, Manipal'
      },
      {
        internImage: incoming1,
        internName: 'Witold Serwatka',
        country: 'Poland',
        experience: 'My experience has been very positive, providing me with extensive insights into neural networks and, more specifically, word embedding. The opportunity to dedicate time to learning about a subject I am passionate about has been immensely rewarding. The structured nature of the internship, with weekly meetings scheduled at specific times, added a valuable layer of organization to the learning process. It has been a fulfilling journey, allowing me to delve into a topic I enjoy and develop a more comprehensive understanding of the subject matter.',
        employer: 'Sikkim Manipal University, Sikkim'
      }
    ],
    Video: [],
  },
};

const RoleAsEmployer = () => {
  const isMobile = useMediaQuery('(max-width:870px)');
  const [category, setCategory] = React.useState('outgoing');
  const [media, setMedia] = React.useState('Story');

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleMediaChange = (value) => {
    setMedia(value);
  };

  return (
    <MainContainer>
      <Container isMobile={isMobile}>
        <Title>Testimonials</Title>
        <SubTitle>Select a category below</SubTitle>
        <DropDrown
          label="What do we eat?"
          options={[
            { label: 'Outgoing Intern', value: 'outgoing' },
            { label: 'Incoming Intern', value: 'incoming' },
          ]}
          value={category}
          onChange={handleCategoryChange}
        />
        <MediaContainer>
          <MediaType
            onClick={() => handleMediaChange('Story')}
            isColored={media === 'Story'}
          >
            <MediaText>Story</MediaText>
          </MediaType>
          {/* <MediaType
            onClick={() => handleMediaChange('Video')}
            isColored={media === 'Video'}
            disabled={true}
          >
            <MediaText>Video</MediaText>
          </MediaType> */}
        </MediaContainer>
        <CardContainer>
          {media === 'Story' &&
            cardContent[category][media].map((item, index) => {
              return (
                <Card
                  internImage={item.internImage}
                  internName={item.internName}
                  country={item.country}
                  experience={item.experience}
                  employer={item.employer}
                  key={index}
                />
              );
            })}
        </CardContainer>
      </Container>
    </MainContainer>
  );
};

export default RoleAsEmployer;
// {cardContent.map((item, key) => {
//     return (

//     );
//   })}
