import { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from "react-ga4";

import Banner from '../components/Banner/Banner';
import BoardMembers from '../components/BoardMembers/BoardMembers';
import Caption from '../components/Caption/Caption';

import BoardBG from '../assets/Banners/Board.jpg';

const MainContainer = styled.div``;

const Board = () => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Board",
  });

  return (
    <MainContainer>
      <Banner
        backgroundImage={BoardBG}
        category="About"
        title="Board of IAESTE India"
        subtitle="Meet our board members"
      />
      <BoardMembers />
      <Caption
        TitleText="Have any feedback or complaints?"
        ButtonText="Contact us"
        ExternalLink="https://iaeste.org/feedback-and-complaints"
        
      />
    </MainContainer>
  );
};

export default Board;
