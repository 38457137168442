import React from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import App from './App';

import Home from './pages/Home';
import Testimonials from './pages/Testimonials';
import Committees from './pages/Committees';
import Board from './pages/Board';
import Students from './pages/Students';
import StudentsHowItWorks from './pages/StudentsHowItWorks';
import StudentsFAQ from './pages/StudentsFAQ';
import Events from './pages/Events';
import Visa from './pages/Visa';
import VisaPreview from './pages/VisaPreview';
import Employers from './pages/Employers';
import EmployersHowItWorks from './pages/EmployersHowItWorks';
import EmployersFAQ from './pages/EmployersFAQ';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';

ReactDom.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="about">
          <Route path="testimonials" element={<Testimonials />} />
          <Route path="committees" element={<Committees />} />
          <Route path="board" element={<Board />} />
        </Route>

        <Route path="students">
          <Route index element={<Students />} />
          <Route path="how-it-works" element={<StudentsHowItWorks />} />
          <Route path="faq" element={<StudentsFAQ />} />
          <Route path="events" element={<Events />} />
          <Route path="visa" element={<Visa />} />
          <Route path="visa/:countryId" element={<VisaPreview />} />
        </Route>

        <Route path="employers">
          <Route index element={<Employers />} />
          <Route path="partner-with-us" element={<Employers />} />
          <Route path="how-it-works" element={<EmployersHowItWorks />} />
          <Route path="faq" element={<EmployersFAQ />} />
        </Route>
        <Route path="contact" element={<Contact />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  </Router>,
  document.getElementById('root')
);
