import styled from 'styled-components';

const Container = styled.div`
  height: 80vh;
  width: 100%;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 0;
  &:before {
    background-color: rgba(0, 0, 0, 0.6);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
`;

const BannerContent = styled.div`
  height: calc(100% - 72px);
  position: absolute;
  overflow: hidden;
  top: 72px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  padding: 0px 5vw;
`;

const Logo = styled.img`
  height: 94px;
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
`;

const Flag = styled.img`
  height: 40px;
`;

const PageTitle = styled.h1`
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  color: white;
  text-align: center;
`;

const Banner = ({ backgroundImage, logo, flag, title }) => {
  return (
    <Container backgroundImage={backgroundImage}>
      <BannerContent>
        <BannerContainer>
          <Logo src={logo} />
          <Flag src={flag} />
          <PageTitle>{title}</PageTitle>
        </BannerContainer>
      </BannerContent>
    </Container>
  );
};

export default Banner;
