import styled from 'styled-components';

const Container = styled.div`
  height: 80vh;
  width: 100%;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 0;
  &:before {
    background-color: rgba(0, 0, 0, 0.6);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
`;

const BannerContent = styled.div`
  height: calc(100% - 72px);
  position: absolute;
  overflow: hidden;
  top: 72px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 10px;
  padding: 0px 5vw;
`;

const PageCategory = styled.div`
  background: #1b75bb;
  color: white;
  font-family: 'Futura Std Heavy';
  padding: 2px 10px;
  font-size: 1.3125rem;
`;

const PageTitle = styled.h1`
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  color: white;
`;

const PageSubtitle = styled.h2`
  font-family: 'Futura Std Medium';
  font-size: 1.125rem;
  color: white;
`;

const Banner = ({ backgroundImage, category, title, subtitle }) => {
  return (
    <Container backgroundImage={backgroundImage}>
      <BannerContent>
        <PageCategory>{category}</PageCategory>
        <PageTitle>{title}</PageTitle>
        <PageSubtitle>{subtitle}</PageSubtitle>
      </BannerContent>
    </Container>
  );
};

export default Banner;
