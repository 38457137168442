import React from 'react';
import { Outlet } from 'react-router-dom';
import ReactGA from 'react-ga4'

import './App.css';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';

import { CssBaseline } from '@mui/material';

let theme = createTheme({
  typography: {
    fontFamily: 'Futura Std Book',
    h1: {
      fontFamily: 'Futura Std Heavy',
    },
    boardHeader: {
      fontFamily: 'Futura Std Heavy',
      fontSize: '12px',
    },
    boardSubheader: {
      fontFamily: 'Futura Std Book',
      fontSize: '8px',
    },
    color: {},
    button: {
      fontFamily: 'Futura Std Heavy',
      fontSize: '1rem',
    },
  },
  palette: {
    background: {
      default: '#fcfdfd',
    },
    text: {
      primary: '#0B3D59',
      secondary: '#7A7A7A',
    },
    primary: {
      main: '#0B3D59',
      light: '#3a7096',
    },
    secondary: {
      main: '#1B75BB',
    },
    divider: {
      default: '#0B3D59',
    },
  },
});

theme = responsiveFontSizes(theme);

// ReactGA.initialize("G-TE27CKS7QV");

ReactGA.initialize([
  {
    trackingId: "G-TE27CKS7QV",
    gtagOptions: {send_page_view: false}
  }
])

const App = () => {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Navbar />
          <Outlet />
          <Footer />
        </CssBaseline>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
