import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';

import Work from '../../assets/Work Experience Discover/Work.jpeg';
import Experience from '../../assets/Work Experience Discover/Experience.jpeg';
import Discover from '../../assets/Work Experience Discover/Discover.jpeg';

import Card from './Card';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  padding: 50px 0;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Title = styled.h1`
  color: #0b3d59;
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  margin: 0px 0px 25px 0px;
  padding: 0;
  text-align: center;
`;

const CardContent = [
  {
    image: Work,
    cardTitle: 'Work',
    cardContent:
      'Apply for a range of internship opportunities and gain new insight into your field by learning from the opportunities provided worldwide.',
  },
  {
    image: Experience,
    cardTitle: 'Experience',
    cardContent:
      'Enrich your cultural understanding and work with a diverse team to learn different techniques and skills.',
  },
  {
    image: Discover,
    cardTitle: 'Discover',
    cardContent:
      'Find out new things about yourself and the world around you during your practical traineeship. Expand your knowledge with real world experiences, connect with new people and make an impact for a better world.',
  },
];

const WorkExperienceDiscover = () => {
  const isMobile = useMediaQuery('(max-width:870px)');
  return (
    <MainContainer isMobile={isMobile}>
      <Title>Work. Experience. Discover</Title>
      <CardContainer>
        {CardContent.map((item, idx) => {
          return (
            <Card
              image={item.image}
              cardTitle={item.cardTitle}
              cardContent={item.cardContent}
              key={idx}
            />
          );
        })}
      </CardContainer>
    </MainContainer>
  );
};

export default WorkExperienceDiscover;
