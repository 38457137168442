import { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from "react-ga4";

import Banner from '../components/Banner/Banner';
import Caption from '../components/Caption/Caption';
import CommitteesBG from '../assets/Banners/Committees.jpg';
import LocalCommittees from '../components/Committees/LocalCommittees/LocalCommittees';
import CooperatingInstitutions from '../components/Committees/CooperatingInstitutions/CooperatingInstitutions';
import CampusAmbassadors from '../components/Committees/CampusAmbassadors/CampusAmbassadors';

const MainContainer = styled.div``;

const CommitteeSection = styled.section`
  margin: 100px 0px;
`;

const Committees = () => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Committees",
  });

  return (
    <MainContainer>
      <Banner
        backgroundImage={CommitteesBG}
        category="About"
        title="Committees of IAESTE India"
        subtitle="Local & Co-operating Committees"
      />
      <CommitteeSection>
        <LocalCommittees />
        <CooperatingInstitutions />
        <CampusAmbassadors />
      </CommitteeSection>
      <Caption
        TitleText="Interested to bring IAESTE to your university?"
        SubtitleText="Become a campus ambassador and be part of the IAESTE family"
        ButtonText="Get in touch with us"
        ButtonLink="/contact"
      />
    </MainContainer>
  );
};

export default Committees;
