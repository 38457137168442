import { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from "react-ga4";

import Banner from '../components/Banner/Banner';
import WhoAreWe from '../components/WhoAreWe/WhoAreWe';
import Caption from '../components/Caption/Caption';

import StudentsBG from '../assets/Banners/Students.png';

const MainContainer = styled.div``;

const Students = () => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Students",
  });

  return (
    <MainContainer>
      <Banner
        backgroundImage={StudentsBG}
        category="Students"
        title="Experience a life-changing internship with IAESTE"
        subtitle="Paid internships in more than 80 countries around the world"
      />
      <WhoAreWe />
      <Caption
        TitleText="Want to become a IAESTE member?"
        SubtitleText="Register in IAESTE Exchange Portal to check out and apply for offers."
        ButtonText="Register"
        ExternalLink="https://iaeste.smartsimple.ie/s_csignup.jsp?token=XVtQHUUGYFxfQBVcXxJXQVZUZklyHXY%3D&ptoken=XVtQC1oGYFxfQBVcXxJXQVZUZklyHXE%3D"
      />
    </MainContainer>
  );
};

export default Students;
