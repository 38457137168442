import React from 'react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';

const List = styled.ul`
  list-style: none;
  display: flex;
  height: 100%;
`;

const ListItem = styled.li`
  padding: 0 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  &:hover {
    background-color: ${(props) =>
      props.scrollY !== 0 ? '#3a7096' : 'rgba(0, 0, 0, 0.3)'};
  }
`;

const MultiListContent = styled.ul`
  list-style: none;
  position: absolute;
  top: 72px;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0px;
  border-radius: 5px;
  display: none;
`;

const MultiListParentItem = styled(ListItem)`
  &:hover {
    ${MultiListContent} {
      display: block;
    }
  }
`;

const ListText = styled.span`
  color: white;
`;

const MultiListHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 74px;
  weight: 100%;
`;

const MultiListItem = styled.li`
  padding: 12px 0px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  &:hover {
    background-color: #ddd;
  }
  &:active {
    transform: scale(0.99);
  }
`;

const DropDownIcon = styled(ArrowDropDownIcon)`
  color: white;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const NavLinks = ({ scrollY }) => {
  return (
    <List>
      <NavLink to="/">
        <ListItem scrollY={scrollY}>
          <ListText>Home</ListText>
        </ListItem>
      </NavLink>

      <MultiListParentItem scrollY={scrollY}>
        <NavLink to="/">
          <MultiListHeader>
            <ListText>About</ListText>
            <DropDownIcon />
          </MultiListHeader>
        </NavLink>

        <MultiListContent>
          <NavLink to="/about/testimonials">
            <MultiListItem>Testimonials</MultiListItem>
          </NavLink>

          <NavLink to="/about/committees">
            <MultiListItem>Committees</MultiListItem>
          </NavLink>

          <NavLink to="/about/board">
            <MultiListItem>Board</MultiListItem>
          </NavLink>

          <ExternalLink href="https://iaeste.org/" target="_blank">
            <MultiListItem>IAESTE A.S.B.L</MultiListItem>
          </ExternalLink>
        </MultiListContent>
      </MultiListParentItem>

      <MultiListParentItem scrollY={scrollY}>
        <NavLink to="/students/">
          <MultiListHeader>
            <ListText>Students</ListText>

            <DropDownIcon />
          </MultiListHeader>
        </NavLink>
        <MultiListContent>
          <NavLink to="/students/how-it-works">
            <MultiListItem>How it works</MultiListItem>
          </NavLink>

          <NavLink to="/students/faq">
            <MultiListItem>FAQs</MultiListItem>
          </NavLink>

          <NavLink to="/students/events">
            <MultiListItem>Events</MultiListItem>
          </NavLink>

          <ExternalLink href="https://iaeste.org/internships" target="_blank">
            <MultiListItem>Internships</MultiListItem>
          </ExternalLink>

          <NavLink to="/students/visa">
            <MultiListItem>Visa Guidelines</MultiListItem>
          </NavLink>
        </MultiListContent>
      </MultiListParentItem>
      <MultiListParentItem scrollY={scrollY}>
        <NavLink to="/employers/partner-with-us">
          <MultiListHeader>
            <ListText>Employers</ListText>
            <DropDownIcon />
          </MultiListHeader>
        </NavLink>
        <MultiListContent>
          <NavLink to="/employers/how-it-works">
            <MultiListItem>How it works</MultiListItem>
          </NavLink>

          <NavLink to="/employers/faq">
            <MultiListItem>FAQs</MultiListItem>
          </NavLink>

          <NavLink to="/employers/partner-with-us">
            <MultiListItem>Partner With Us</MultiListItem>
          </NavLink>
        </MultiListContent>
      </MultiListParentItem>

      <ExternalLink href='https://magazine.iaeste.in' target='_blank'>
        <ListItem scrollY={scrollY}>
          <ListText>Magazine</ListText>
        </ListItem>
      </ExternalLink>

      <NavLink to="/contact">
        <ListItem scrollY={scrollY}>
          <ListText>Contact us</ListText>
        </ListItem>
      </NavLink>
    </List>
  );
};

export default NavLinks;
