import styled from 'styled-components';

import Number1 from '../../../assets/Icons/Numbers/1.svg';
import Number2 from '../../../assets/Icons/Numbers/2.svg';
import Number3 from '../../../assets/Icons/Numbers/3.svg';
import Number4 from '../../../assets/Icons/Numbers/4.svg';
import Number5 from '../../../assets/Icons/Numbers/5.svg';
import Number6 from '../../../assets/Icons/Numbers/6.svg';
import Number7 from '../../../assets/Icons/Numbers/7.svg';
import Number8 from '../../../assets/Icons/Numbers/8.svg';
import Number9 from '../../../assets/Icons/Numbers/9.svg';
import Number10 from '../../../assets/Icons/Numbers/10.svg';

const Numbers = [
  Number1,
  Number2,
  Number3,
  Number4,
  Number5,
  Number6,
  Number7,
  Number8,
  Number9,
  Number10,
];

const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 2rem 0 2rem;
  margin: 30px 0px;
  transition: transform 0.3s;
  background: ${(props) => props.BackgroundColor};

  border-radius: 8px;

  &:hover {
    transform: scale(1.02);
  }
`;

const CardTitle = styled.h1`
  font-family: 'Futura Std Heavy';
  font-size: 1.3125rem;
  margin: 50px 0 25px 0px;
  padding: 0;
  color: ${(props) => props.TextColor};
`;

const CardParagraph = styled.p`
  font-size: 1.3125rem;
  margin: 0 0 25px 0px;
  padding: 0;
  text-align: justify;
  color: ${(props) => props.TextColor};
`;

const ParaSpacing = styled.div`
  margin: 25px 0 0 0;
`;

const PointsContainer = styled.div`
  margin: 25px 0;
`;

const PointContainer = styled.div`
  display: flex;
`;

const PointInnerContainer = styled.div`
  margin: 0 0 0 20px;
`;

const PointParagraph = styled.p`
  font-size: 1.3125rem;
  margin: 0 0 25px 0;
  padding: 0;
  color: ${(props) => props.TextColor};
`;
const PointImage = styled.img`
  width: 1.8rem;
  height: 1.8rem;
`;

const RegularCard = ({
  BackgroundColor,
  TextColor,
  TitleContent,
  ParagraphContent,
  PointContent,
}) => {
  return (
    <Container BackgroundColor={BackgroundColor}>
      {TitleContent && (
        <CardTitle TextColor={TextColor}>{TitleContent}</CardTitle>
      )}

      {ParagraphContent && (
        <ParaSpacing>
          {ParagraphContent.map((item, index) => {
            return (
              <CardParagraph TextColor={TextColor} key={index}>
                {item}
              </CardParagraph>
            );
          })}
        </ParaSpacing>
      )}

      {PointContent && (
        <PointsContainer>
          {PointContent.map((item, index) => {
            return (
              <PointContainer>
                <PointImage src={Numbers[index]} />
                <PointInnerContainer>
                  {item.map((innerItem, innerIndex) => {
                    return (
                      <PointParagraph TextColor={TextColor} key={innerIndex}>
                        {innerItem}
                      </PointParagraph>
                    );
                  })}
                </PointInnerContainer>
              </PointContainer>
            );
          })}
        </PointsContainer>
      )}
    </Container>
  );
};

export default RegularCard;
