import { Button } from '@mui/material';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';

const MainContainer = styled.div`
  margin: 100px 0px 60px 0px;
  padding: 30px 0px;
  background-color: #0b3d59;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 25px;
  padding: 25px 0px;
`;

const Title = styled.h1`
  font-size: 2.25rem;
  font-family: 'Futura Std Heavy';
  color: white;
  text-align: center;
  padding: 0px 10px;
  margin: 0;
`;

const Subtitle = styled.p`
  font-size: 1.125rem;
  font-family: 'Futura Std Medium';
  color: white;
  text-align: center;
  padding: 0px 10px;
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 25px;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const Caption = ({
  TitleText,
  SubtitleText,
  ButtonText,
  ButtonLink,
  ButtonText2,
  ButtonLink2,
  ExternalLink,
}) => {
  return (
    <MainContainer>
      <Container>
        <Title>{TitleText}</Title>
        <Subtitle>{SubtitleText}</Subtitle>
        <ButtonContainer>
          {ExternalLink && (
            <Button
              variant="contained"
              color="secondary"
              endIcon={<LaunchIcon />}
              href={ExternalLink}
              target="_blank"
            >
              {ButtonText}
            </Button>
          )}
          {ButtonLink && (
            <NavLink to={ButtonLink}>
              <Button variant="contained" color="secondary">
                {ButtonText}
              </Button>
            </NavLink>
          )}
          {ButtonLink2 && (
            <NavLink to={ButtonLink2}>
              <Button variant="contained" color="secondary">
                {ButtonText2}
              </Button>
            </NavLink>
          )}
        </ButtonContainer>
      </Container>
    </MainContainer>
  );
};

export default Caption;
