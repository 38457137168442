import { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from "react-ga4";

import Banner from '../components/Banner/Banner';
import HowItWorks from '../components/HowItWorks/Employer/HowItWorks';
import Caption from '../components/Caption/Caption';
import EmployersHowItWorksBG from '../assets/Banners/EmployerHowItWorks.jpg';

const MainContainer = styled.div``;

const EmployersHowItWorks = () => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "EmployersHowItWorks",
  });

  return (
    <MainContainer>
      <Banner
        backgroundImage={EmployersHowItWorksBG}
        category="Employers"
        title="How to hire an IAESTE intern"
        subtitle="Access to the best young talent from around the world"
      />
      <HowItWorks />
      <Caption
        TitleText="Need to contact us for more specific details?"
        SubtitleText="Reach out to us from our Contact page or drop a mail to coporate@iaeste.in with your query"
        ButtonText="Contact us"
        ButtonLink="/contact"
      />
    </MainContainer>
  );
};

export default EmployersHowItWorks;
