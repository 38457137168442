import styled from 'styled-components';

const CardContainer = styled.div`
  margin: 0 25px;
  border-radius: 8px;
`;

const CardHeader = styled.div`
  width: 250px;
  height: 250px;
  position: relative;
  transition: 0.7s;
  transform: translateY(125px);
  z-index: 1;
  border-radius: 8px;

  ${CardContainer}:hover & {
    transform: translateY(0px);
  }
`;

const HeaderImage = styled.img`
  width: 250px;
  height: 250px;
  border-radius: 8px;
`;

const CardFooter = styled.div`
  width: 250px;
  height: 250px;
  background-color: white;
  position: relative;
  transition: 0.8s;
  transform: translateY(-125px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 0px 0px 8px 8px;
  ${CardContainer}:hover & {
    transform: translateY(0px);
  }
`;

const FooterTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
  color: #0b3d59;
  text-align: center;
`;

const FooterContent = styled.p`
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  color: #7a7a7a;
  text-align: center;
`;

const FooterStripe = styled.div`
  background-color: #1c74bb;
  width: 100%;
  height: 10px;
  border-radius: 0px 0px 8px 8px;
`;

const Card = ({ image, cardContent, cardTitle }) => {
  return (
    <CardContainer>
      <CardHeader>
        <HeaderImage src={image} />
      </CardHeader>
      <CardFooter>
        <FooterTitle>{cardTitle}</FooterTitle>
        <FooterContent>{cardContent}</FooterContent>
        <FooterStripe />
      </CardFooter>
    </CardContainer>
  );
};

export default Card;
