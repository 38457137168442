import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';
import { css } from 'styled-components';

import StatsBar from './StatsBar';
import WorkExperienceDiscover from '../WorkExperienceDiscover/WorkExperienceDiscover';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0px;
`;

const Container = styled.div`
  width: 70vw;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.isMobile && `width: 90vw`};
`;

const Title = styled.h1`
  color: #0b3d59;
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  margin: 0px 0px 50px 0px;
  padding: 0;
  text-align: center;
`;

const ParaContent = styled.p`
  font-size: 1rem;
  color: #7a7a7a;
  margin: 0px;
  padding: 0;
  text-align: center;
`;

const WhoAreWe = () => {
  const isMobile = useMediaQuery('(max-width:870px)');

  return (
    <MainContainer>
      <Container isMobile={isMobile}>
        <Title>Who Are We?</Title>
        <ParaContent>
          The International Association for the Exchange of Students for
          Technical Experience, commonly referred to as IAESTE, is a non-profit,
          international organization that serves as a platform for exchanging
          students for technical work experience abroad. IAESTE provides paid,
          technical internships ranging from 4-52 weeks to students who are
          currently enrolled in a recognized university or college.
        </ParaContent>
        <br />
        <ParaContent>
          IAESTE India a is a full member of the IAESTE network which
          encompasses more than 80 countries worldwide. In India, the National
          Committee of IAESTE India is a completely student organization that
          has achieved a staggering growth since it’s founding in 2014, and now
          serves 5,000 students each year and has over 50 partnerships with
          corporate employers.
        </ParaContent>
        <StatsBar />
        <WorkExperienceDiscover />
      </Container>
    </MainContainer>
  );
};

export default WhoAreWe;
