import { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from "react-ga4";

import Banner from '../components/Banner/Banner';
import Faq from '../components/Faq/Faq';
import Caption from '../components/Caption/Caption';
import EmpoyersFaqBG from '../assets/Banners/FAQ.jpeg';

const MainContainer = styled.div``;

const EmployersFAQ = () => {
  const FAQ = [
    {
      Query: 'How long can I have an IAESTE intern for?',
      Response:
        'A typical IAESTE internship lasts anywhere from 6 weeks to 1 year. Ideally, it should be for a minimum of 3 months in order for both parties to gain the maximum benefit from the experience.',
    },
    {
      Query: 'What support do I receive when hiring an IAESTE intern?',
      Response:
        'Your local or national IAESTE office will support you throughout the entire process, from liaising with you about candidates, supporting with work permits/visas, through to providing a social programme and arranging accommodation. Our goal is to make hiring an IAESTE intern as stress-free as possible for you.',
    },
    {
      Query: 'Do I need to arrange accommodation for my IAESTE intern?',
      Response:
        'No, accommodation is arranged by the local or national office for the intern. However, if an employer has the possibility to assist with accommodation, this will be appreciated.',
    },
    {
      Query: 'How many candidates do I get to choose from?',
      Response:
        'This very much depends on your requirements and when you wish to hire an intern. All our applicants have already been through a rigorous selection process, so when we forward a candidate to you, we are confident you will approve them. In some instances, you can request to receive multiple candidates to select from.',
    },
    {
      Query: 'Which countries could I potentially hire an intern from?',
      Response: '',
    },
    {
      Query: 'How much does it cost to hire an IAESTE intern?',
      Response:
        'As a minimum, you will need to pay the student a salary/stipend that covers their accommodation, food and local travel (we call this a cost of living allowance). Students are responsible for paying their own international travel costs. Some countries charge employers a fee for recruiting an IAESTE intern.',
    },
    {
      Query: 'What educational/experience levels do IAESTE interns have?',
      Response:
        'The educational/experience level will be set by you, but all IAESTE interns will have completed at least 2 years of their undergraduate degree course. This is because we expect them to be able to use the knowledge they have gained during this time to benefit the employer.',
    },
    {
      Query: 'What kind of work can an IAESTE intern do?',
      Response:
        'IAESTE interns are capable of doing all kinds of professional work. It very much depends on your requirements. We expect employers to provide real work experience that the student will gain valuable experience from. This does exclude manual labour.',
    },
    {
      Query: 'Can I have an intern from a particular country?',
      Response:
        'We are present in 80+ countries and where possible to will try to accommodate specific requests but we ask that you are as open-minded as possible in terms of where your intern comes from.',
    },
    {
      Query: 'Does IAESTE provide a social programme for their interns?',
      Response:
        'In most cases, a social programme will be provided by the local or national IAESTE committee. In some cases, due to logistics or geographical difficulties, it may not be possible, but interns are always put in touch with other IAESTE interns in the country.',
    },
    {
      Query: 'How soon can I hire an IAESTE intern?',
      Response:
        'We have a worldwide network of students waiting for opportunities at all times of the year. In some cases, you could have an intern start with you in a month after you have first contacted us. It may be longer if visa/work permits are required or you have specific requirements beyond our typical profiles.',
    },
    {
      Query:
        'A student has contacted me, can I hire them under the IAESTE programme?',
      Response:
        'It may be possible to do this under what we call a ‘Reserved Offer’. In most cases, you will need to pay a fee for this service and you should contact your national or local office for details.',
    },
  ];

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "EmployersFAQ",
  });

  return (
    <MainContainer>
      <Banner
        backgroundImage={EmpoyersFaqBG}
        category="Employers"
        title="How can we help you?"
        subtitle="FAQs about IAESTE internships"
      />
      <Faq FaqArray={FAQ} />
      <Caption
        TitleText="Need more information on becoming a partner?"
        SubtitleText="Reach out to us from our Contact page"
        ButtonText="Contact us"
        ButtonLink="/contact"
      />
    </MainContainer>
  );
};

export default EmployersFAQ;
