import React, { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import styled from 'styled-components';

import NavLinks from './Default/NavLinks';
import MobileNavLinks from './Mobile/MobileNavLinksMenu';
import MobileNavLinksAbout from './Mobile/MobileNavLinksAbout';
import MobileNavLinksEmployees from './Mobile/MobileNavLinksEmployees';
import MobileNavLinksStudents from './Mobile/MobileNavLinksStudents';
import { MenuToggle } from './MenuToggle';

import logo from '../../assets/logo.png';

const MainContainer = styled.section`
  height: 72px;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  transition: background-color 0.5s ease;
  z-index: 1000;
  font-family: 'Futura Std Medium';
  ${(props) =>
    (props.scrollY !== 0 || props.isOpen) &&
    `
    background-color: #0B3D59
    `}
`;

const Container = styled.div`
  padding: 0 10vw;
  width: 100%;
  height: 100%;
`;

const Navigation = styled.nav`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
`;

const MobileContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 1s ease-in-out;
`;
// 870px break

const Navbar = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isOpen, setOpen] = useState(false);

  function logit() {
    setScrollY(window.pageYOffset);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', logit);
    }
    watchScroll();
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener('scroll', logit);
    };
  }, []);

  const isMobile = useMediaQuery('(max-width:870px)');

  const [isMenu, setMenu] = useState(false);
  const [isSub1, setSub1] = useState(true);
  const [isSub2, setSub2] = useState(true);
  const [isSub3, setSub3] = useState(true);

  const handleMultiClick = (target) => {
    setMenu(true);
    if (target === 0) {
      setSub1(false);
    } else if (target === 1) {
      setSub2(false);
    } else {
      setSub3(false);
    }
  };

  const handleBackMenuClick = (target) => {
    setMenu(false);
    if (target === 0) {
      setSub1(true);
    } else if (target === 1) {
      setSub2(true);
    } else {
      setSub3(true);
    }
  };

  const handleClick = (target) => {
    setOpen(false);
    if (target === 0) {
      setSub1(true);
    } else if (target === 1) {
      setSub2(true);
    } else if (target === 2) {
      setSub3(true);
    }
  };

  return (
    <MainContainer scrollY={scrollY} isOpen={isOpen}>
      <Container>
        <Navigation>
          <Logo>
            <img
              src={logo}
              alt="IAESTE India Logo"
              height="54px"
              width="auto"
            />
          </Logo>
          {!isMobile && <NavLinks scrollY={scrollY} />}
          {isMobile && (
            <MobileContainer>
              <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
              {isOpen && (
                <MobileNavLinks
                  hidden={isMenu}
                  handleMultiClick={handleMultiClick}
                  handleClick={handleClick}
                />
              )}
              {isOpen && (
                <MobileNavLinksAbout
                  isHidden={isSub1}
                  handleBackMenuClick={handleBackMenuClick}
                  handleClick={handleClick}
                />
              )}
              {isOpen && (
                <MobileNavLinksStudents
                  isHidden={isSub2}
                  handleBackMenuClick={handleBackMenuClick}
                  handleClick={handleClick}
                />
              )}
              {isOpen && (
                <MobileNavLinksEmployees
                  isHidden={isSub3}
                  handleBackMenuClick={handleBackMenuClick}
                  handleClick={handleClick}
                />
              )}
            </MobileContainer>
          )}
        </Navigation>
      </Container>
    </MainContainer>
  );
};

export default Navbar;
