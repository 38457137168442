import styled from 'styled-components';

import Members from '../../assets/Who are we/Members.png';
import Internships from '../../assets/Who are we/Internships.png';
import Companies from '../../assets/Who are we/Companies.png';
import Countries from '../../assets/Who are we/Countries.png';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin: 50px 0;
`;

const StatContainer = styled.div`
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 225px;
`;

const Stat = styled.h1`
  margin: 20px 0;
  padding: 0;
  font-size: 1.3rem;
  color: #0b3d59;
  text-align: center;
`;

const StatName = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  color: #0b3d59;
  text-align: center;
`;

const IconContainer = styled.div`
  height: 95px;
  width: 95px;
  border-radius: 50%;
  background-color: #f3f8fd;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
  filter: invert(19%) sepia(11%) saturate(5307%) hue-rotate(167deg)
    brightness(93%) contrast(94%);
`;

const StatsBar = () => {
  return (
    <Container>
      <StatContainer>
        <IconContainer>
          <Icon src={Members} />
        </IconContainer>
        <Stat>4000</Stat>
        <StatName>Members in India</StatName>
      </StatContainer>

      <StatContainer>
        <IconContainer>
          <Icon src={Internships} />
        </IconContainer>
        <Stat>500</Stat>
        <StatName>Internships</StatName>
      </StatContainer>

      <StatContainer>
        <IconContainer>
          <Icon src={Companies} />
        </IconContainer>
        <Stat>10</Stat>
        <StatName>Companies*</StatName>
      </StatContainer>

      <StatContainer>
        <IconContainer>
          <Icon src={Countries} />
        </IconContainer>
        <Stat>82</Stat>
        <StatName>Countries</StatName>
      </StatContainer>
    </Container>
  );
};

export default StatsBar;
