import styled from 'styled-components';
import { Divider, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import Facebook from '../../../assets/Icons/facebook.svg';
import Instagram from '../../../assets/Icons/instagram.svg';
import Linkedin from '../../../assets/Icons/linkedin.svg';
import Twitter from '../../../assets/Icons/twitter.svg';

const ListContainer = styled.div`
  margin: 0;
  padding: 20px;

  display: flex;
  height: calc(100vh - 72px);
  flex-direction: column;
  width: 100%;
  position: fixed;
  top: 72px;
  left: 0;
  background-color: white;
  ${(props) =>
    props.isHidden &&
    `
    display: none;
    `}
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  padding: 10px 24px;
`;

const ListText = styled.span`
  color: #0b3d59;
`;

const SocialIcons = styled.div`
  padding: 10px 0px;
  display: flex;
  justify-content: center;
`;

const PreviousMenu = styled.div`
  display: flex;
  padding: 15px 0px;
`;

const NavigateBefore = styled(NavigateBeforeIcon)`
  color: #0b3d59;
`;

const Icon = styled.img`
  height: 1.5rem;
  margin: 0px ${(props) => props.margin || 0};
  margin-left: ${(props) => props.marginLeft || 0};
  margin-right: ${(props) => props.marginRight};
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const MobileNavLinksEmployees = ({
  isHidden,
  handleBackMenuClick,
  handleClick,
}) => {
  return (
    <ListContainer isHidden={isHidden}>
      <List>
        <PreviousMenu onClick={() => handleBackMenuClick(2)}>
          <NavigateBefore />
          <ListText>Back to Menu</ListText>
        </PreviousMenu>
        <NavLink to="/employers/partner-with-us" onClick={() => handleClick(2)}>
          <ListItem>
            <ListText>Partner With Us</ListText>
          </ListItem>
        </NavLink>
        <NavLink to="/employers/how-it-works" onClick={() => handleClick(2)}>
          <ListItem>
            <ListText>How It Works</ListText>
          </ListItem>
        </NavLink>
        <NavLink to="/employers/faq" onClick={() => handleClick(2)}>
          <ListItem>
            <ListText>FAQs</ListText>
          </ListItem>
        </NavLink>
      </List>

      <Divider sx={{ margin: '10px 0 0 0', color: '#0B3D59' }} />
      <SocialIcons>
        <IconButton
          aria-label="IAESTE India Facebook"
          href="https://www.facebook.com/iaesteindia/"
          target="_blank"
        >
          <Icon src={Facebook} />
        </IconButton>
        <IconButton
          aria-label="IAESTE India Instagram"
          href="https://www.instagram.com/iaeste_india/?hl=en"
          target="_blank"
        >
          <Icon src={Instagram} />
        </IconButton>
        <IconButton
          aria-label="IAESTE India Linkedin"
          href="https://www.linkedin.com/company/iaeste-india/"
          target="_blank"
        >
          <Icon src={Linkedin} />
        </IconButton>
        <IconButton
          aria-label="IAESTE India Twitter"
          href="https://twitter.com/iaeste_india"
          target="_blank"
        >
          <Icon src={Twitter} />
        </IconButton>
      </SocialIcons>
    </ListContainer>
  );
};

export default MobileNavLinksEmployees;
