import styled from 'styled-components';
import { IconButton } from '@mui/material';

import Gmail from '../../../assets/Icons/gmail.svg';
import Linkedin from '../../../assets/Icons/linkedin.svg';

const Card = styled.div`
  width: 200px;
  background-color: white;

  box-shadow: 0 6px 20px 0 rgba(13, 51, 32, 0.1);
  border-radius: 8px;
  text-align: center;
`;

const CardImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 8px 8px 0 0;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardTitle = styled.h1`
  font-family: 'Futura Std Heavy';
  font-size: 0.75rem;
  margin: 10px;
`;

const CardSubtitle = styled.h2`
  color: #7a7a7a;
  font-size: 0.5rem;
  letter-spacing: 0;
  margin: 5px;
`;

const CardSocials = styled.div``;

const Icon = styled.img`
  height: 15px;
  margin: 0px ${(props) => props.margin || 0};
  margin-left: ${(props) => props.marginLeft || 0};
  margin-right: ${(props) => props.marginRight};
`;

const BoardCard = ({ image, name, title, mail, linkedin }) => {
  return (
    <Card>
      <CardImage src={image} />
      <CardContent>
        <CardTitle>{name}</CardTitle>
        <CardSubtitle>{title}</CardSubtitle>
        <CardSocials>
          <IconButton
            aria-label={`${name} Gmail`}
            href={`mailto:${mail}`}
            target="_blank"
          >
            <Icon src={Gmail} />
          </IconButton>
          <IconButton
            aria-label={`${name} Linkedin`}
            href={linkedin}
            target="_blank"
          >
            <Icon src={Linkedin} />
          </IconButton>
        </CardSocials>
      </CardContent>
    </Card>
  );
};

export default BoardCard;
