import { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from "react-ga4";

import Banner from '../components/Banner/Banner';
import Caption from '../components/Caption/Caption';
import HowItWorks from '../components/HowItWorks/Student/HowItWorks';
import StudentsHowItWorksBG from '../assets/Banners/StudentsHowItWorks.jpg';

const MainContainer = styled.div``;

const StudentsHowItWorks = () => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "StudentsHowItWorks",
  });

  return (
    <MainContainer>
      <Banner
        backgroundImage={StudentsHowItWorksBG}
        category="Students"
        title="Become a member and apply for international offers"
        subtitle="How IAESTE internship works."
      />
      <HowItWorks />
      <Caption
        TitleText="Need more information on certain processes?"
        SubtitleText="Reach out to us from our Contact page"
        ButtonText="Contact us"
        ButtonLink="/contact"
      />
    </MainContainer>
  );
};

export default StudentsHowItWorks;
