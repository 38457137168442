import styled from 'styled-components';

import Card from './Card';
import FirstImage from '../../../assets/How it works illustrations/1.jpg';
import SecondImage from '../../../assets/How it works illustrations/2.jpg';
import ThirdImage from '../../../assets/How it works illustrations/3.jpg';
import FourthImage from '../../../assets/How it works illustrations/4.jpg';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0px;
`;

const Container = styled.div`
  width: 90vw;
`;

const Title = styled.h1`
  color: #0b3d59;
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  margin: 0px 0px 50px 0px;
  text-align: center;
`;

const HowItWorks = () => {
  return (
    <MainContainer>
      <Container>
        <Title>How It Works</Title>
        <Card
          StepIllustration={FirstImage}
          StepNo="01"
          StepTitle="Be a part of the IAESTE family"
          Instructions="It all begins with getting in touch with us. We are always on the lookout for prospective companies and so if we come across you, or you come across us, please feel free to reach out and get welcomed into the IAESTE family."
        />
        <Card
          StepIllustration={SecondImage}
          StepNo="02"
          StepTitle="Create an internship"
          Instructions="The second step toward becoming an IAESTE employer is to fill the Offer Form. The offer form specifies the duration, field of specialization along with the kind of work and stipend offered to the student, along with a few details regarding your company."
        />
        <Card
          StepIllustration={ThirdImage}
          StepNo="03"
          StepTitle="Select suitable candidate"
          Instructions="Once filled and sent back to us, IAESTE finds the best suited candidates from it’s member pool according to the specifications mentioned by you and forwards the application of that candidate.
          You may further review the candidate via a Skype or telephonic interview to finalise him/her or seek another candidate instead."
        />
        <Card
          StepIllustration={FourthImage}
          StepNo="04"
          StepTitle="Host the Intern"
          Instructions="If accepted, IAESTE will provide the student with the necessary assistance required in obtaining a visa and arranging for accommodation along with their reception in India, with minimum hassle to the company."
        />
      </Container>
    </MainContainer>
  );
};

export default HowItWorks;
