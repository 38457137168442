import { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from "react-ga4";

import Banner from '../components/Banner/Banner';
import Faq from '../components/Faq/Faq';
import Caption from '../components/Caption/Caption';

import StudentsFaqBG from '../assets/Banners/FAQ.jpeg';

const MainContainer = styled.div``;

const StudentsFAQ = () => {
  const FAQ = [
    {
      Query: 'Who is eligible for an IAESTE internship?',
      Response:
        'To undertake an IAESTE internship you must be enrolled in a degree course relating to the study field of the internship you wish to apply for. Some IAESTE countries require you to have completed 2 years of your course before undertaking an IAESTE internship.',
    },
    {
      Query: 'What study fields are eligible for an IAESTE internship?',
      Response:
        'The vast majority of our internships are in science, engineering and applied arts fields (architecture/design) but we do sometimes have internships relevant to other fields (business, marketing etc.)',
    },
    {
      Query: 'How long are IAESTE internships?',
      Response:
        'IAESTE internships range anywhere from 6 weeks to a year. Many are for 20-months during the summer but internships are available all year round.',
    },
    {
      Query: 'Are IAESTE internships paid?',
      Response:
        'All IAESTE on-site internships are paid a cost of living allowance which as a minimum will cover your accommodation, foods and local travel. Some remote internships may offer alternative arrangements and these will be stated in the internship information.',
    },
    {
      Query: 'Does IAESTE offer scholarships?',
      Response:
        'No, we do not provide scholarships, payment for all our internships comes from your internship employer.',
    },
    { Query: 'Do I have to pay a fee?', Response: '' },
    {
      Query: 'Do I have to arrange my own accommodation?',
      Response:
        'No, the national or local committee in the country you are visiting will arrange accommodation for you and you will be provided with the details in advance.',
    },
    {
      Query: 'What happens if I need a visa or work permit?',
      Response:
        'If you require a visa or work permit to undertake your internship then you will be provided with all the information you need to apply for it.',
    },
    {
      Query: 'Will I be with other students on my internship?',
      Response:
        'In most cases, there will be other IAESTE or local students around during the period of your internship. If there is not, then you will still be integrated into your work’s network, so you will not be alone.',
    },
    {
      Query: 'Will there be a social programme during my internships?',
      Response:
        'In many cases (especially if your internship is in the summer, there will be some form of social programme, giving you the opportunity to explore the country of your internship and meet other IAESTE students from around the world. If there is not a social programme, or it’s outside of the summer, then you will still be put in contact with other interns in your area/country so you can make your own arrangements to meet up and plan trips.',
    },
    {
      Query: 'Does my internship include an insurance?',
      Response: 'IAESTE recommends to be covered during your trip/internship.',
    },
  ];

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "StudentsFAQ",
  });

  return (
    <MainContainer>
      <Banner
        backgroundImage={StudentsFaqBG}
        category="Students"
        title="How can we help you?"
        subtitle="FAQs about IAESTE internships"
      />
      <Faq FaqArray={FAQ} />
      <Caption
        TitleText="Couldn’t find the answers for your query?"
        SubtitleText="Reach out to us from our Contact page"
        ButtonText="Contact us"
        ButtonLink="/contact"
      />
    </MainContainer>
  );
};

export default StudentsFAQ;
