import styled from 'styled-components';

import MinimizedCard from './MinimizedCard';
import FirstImage from '../../../assets/How it works illustrations/1.jpg';
import SecondImage from '../../../assets/How it works illustrations/2.jpg';
import ThirdImage from '../../../assets/How it works illustrations/3.jpg';
import FourthImage from '../../../assets/How it works illustrations/4.jpg';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0px;
`;

const Container = styled.div`
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  color: #0b3d59;
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  margin: 0px 0px 50px 0px;
  text-align: center;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 2rem;
  column-gap: 2rem;
`;

const HowItWorks = () => {
  return (
    <MainContainer>
      <Container>
        <Title>How It Works</Title>
        <CardContainer>
          <MinimizedCard
            StepIllustration={FirstImage}
            StepTitle="Be a part of the IAESTE family"
          />
          <MinimizedCard
            StepIllustration={SecondImage}
            StepTitle="Create an internship"
          />
          <MinimizedCard
            StepIllustration={ThirdImage}
            StepTitle="Select suitable candidate"
          />
          <MinimizedCard
            StepIllustration={FourthImage}
            StepTitle="Host the Intern"
          />
        </CardContainer>
      </Container>
    </MainContainer>
  );
};

export default HowItWorks;
