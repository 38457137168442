import React from 'react';

import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';

import RegularCard from './RegularCard';

import partner1 from '../../assets/Patner with us/1.png';
import partner2 from '../../assets/Patner with us/2.png';
import partner3 from '../../assets/Patner with us/3.png';
import partner4 from '../../assets/Patner with us/4.png';
import partner5 from '../../assets/Patner with us/5.png';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0px;
`;

const Container = styled.div`
  width: 70vw;
  ${(props) => props.isMobile && `width: 90vw`}
`;

const Title = styled.h1`
  color: #0b3d59;
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  margin: 0px 0px 50px 0px;
  text-align: center;
`;

const ContainerContent = styled.p`
  font-family: 'Futura Std Heavy';
  color: #7a7a7a;
  font-size: 1.125rem;
  text-align: center;
`;
const BoldContent = styled.span`
  font-family: 'Futura Std Heavy';
`;



const cardContent = [
  {
    title: 'Skilled Interns',
    paragraph:
      'Our interns are motivated who can lend a fresh perspective on the way of doing things and introduce diversity in thought and culture, thereby impacting your business in a positive manner.',
    image: partner1,
    alt: 'Skilled Intern Illustration',
    direction: 'left',
  },
  {
    title: 'Diverse Partners',
    paragraph:
      'Our partners range from Multinational Companies to start-ups and small private firms. ',
    image: partner2,
    alt: 'Diverse Partners Illustration',
    direction: 'right',
  },
  {
    title: 'Student Centric',
    paragraph:
      'Student-run projects are welcome to hire interns as well, given they meet our eligibility criteria. ',
    image: partner3,
    alt: 'Student Centric Illustration',
    direction: 'left',
  },
  {
    title: 'Flexible Durations',
    paragraph:
      'IAESTE interns are available both for short term and long term durations and in a general or specialized field.',
    image: partner4,
    alt: 'Flexible Durations Illustration',
    direction: 'right',
  },
  {
    title: 'Support from IAESTE',
    paragraph:
      'IAESTE provides visa assistance in bringing the student to India and helps the student in arranging accommodation with minimum hassle to the company.',
    image: partner5,
    alt: 'Support from IAESTE Illustration',
    direction: 'left',
  },
];

const PartnerWithUs = () => {
  const isMobile = useMediaQuery('(max-width:870px)');

  return (
    <MainContainer>
      <Container isMobile={isMobile}>
        <Title>Partner With Us</Title>
        <ContainerContent>
          IAESTE India welcomes associations with corporates and potential
          employers looking to hire foreign interns. IAESTE provides its
          employers access to a large talent pool of highly qualified students
          with a strong technical background from over 80 countries.
        </ContainerContent>
        {cardContent.map((item, index) => {
          return (
            <RegularCard
              TitleContent={item.title}
              ParaContent={item.paragraph}
              ImageContent={item.image}
              ImageAlt={item.alt}
              Direction={item.direction}
              key={index}
              IsMobile={isMobile}
            />
          );
        })}
      </Container>
    </MainContainer>
  );
};

export default PartnerWithUs;
