import { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from "react-ga4";

import Banner from '../components/Banner/Banner';
import VisaMain from '../components/Visa/VisaMain/VisaMain';
import Caption from '../components/Caption/Caption';
import VisaBG from '../assets/Banners/VisaGuidelines.jpeg';

const MainContainer = styled.div``;

const Visa = () => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Visa",
  });

  return (
    <MainContainer>
      <Banner
        backgroundImage={VisaBG}
        category="Students"
        title="Visa Guidelines"
        subtitle="Visa information for Incoming Students"
      />
      <VisaMain />
      <Caption
        TitleText="Need more information on Visa?"
        SubtitleText="Reach out to us from our Contact page or drop a mail to visa@iaeste.in with your query"
        ButtonText="Contact us"
        ButtonLink="/contact"
      />
    </MainContainer>
  );
};

export default Visa;
