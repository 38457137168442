import styled, { css } from 'styled-components';

const MainContainer = styled.div`
  height: 232px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
  margin: 50px 0;

  &:hover {
    transform: scale(1.02);
  }

  ${(props) => props.isRight && 'justify-content: space-between'}
`;

const CardContent = styled.p`
  font-family: 'Futura Std Heavy';
  font-size: 1.125rem;
  margin: 0;
  padding: 0;
  max-width: 335px;
  color: #ffffff;
  ${(props) =>
    props.isMobile &&
    css`
      position: absolute;
      background-color: rgba(11, 61, 89, 0.6);
      height: 232px;
      width: 90vw;
      border-radius: 8px;
      text-align: center;
      display: flex;

      align-items: center;
    `};
`;

const Illustration = styled.img`
  height: 100%;
  width: ${(props) => (props.isMobile ? '100%' : '240px')};
  object-fit: cover;
  border-radius: 8px 0 0 8px;
  ${(props) => props.isRight && `border-radius: 0 8px 8px 0`}
`;

const RegularCard = ({ RoleDescription, ImageContent, ImageAlt, IsMobile }) => {
  return (
    <MainContainer>
      <Illustration isMobile={IsMobile} src={ImageContent} alt={ImageAlt} />
      <CardContent isMobile={IsMobile}>{RoleDescription}</CardContent>
    </MainContainer>
  );
};

export default RegularCard;
