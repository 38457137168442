import styled from 'styled-components';

import CommitteesCard from '../CommitteesCard/CommitteesCard';

import MU from '../../../assets/Committees/LC/MU.jpeg';
import KU from '../../../assets/Committees/LC/KU.png';
import MUJ from '../../../assets/Committees/LC/MUJ.jpg';
import JECRC from '../../../assets/Committees/LC/JECRC.jpeg';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 90vw;
  margin-bottom: 50px;
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 2rem;
  column-gap: 2rem;
`;

const Title = styled.h1`
  color: #0b3d59;
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  margin: 0px 0px 50px 0px;
  text-align: center;
`;

const LocalCommittees = () => {
  return (
    <MainContainer>
      <Container>
        <Title>Local Committees</Title>
        <Cards>
          <CommitteesCard
            BackgroundImage={MU}
            CommitteesName="Manipal University"
          />
          <CommitteesCard
            BackgroundImage={MUJ}
            CommitteesName="Manipal University Jaipur"
          />
          <CommitteesCard
            BackgroundImage={KU}
            CommitteesName="Karunya Institute of Technology and Sciences"
          />
          <CommitteesCard
            BackgroundImage={JECRC}
            CommitteesName="JECRC University"
          />
        </Cards>
      </Container>
    </MainContainer>
  );
};

export default LocalCommittees;
