import { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from "react-ga4";

import HomeBanner from '../components/Banner/HomeBanner';
import WhoAreWe from '../components/WhoAreWe/WhoAreWe';
import Caption from '../components/Caption/Caption';

import HomeBg from '../assets/Banners/Home.jpg';

import IaesteLogo from '../assets/logo.png';
import Flag from '../assets/Country/india.png';

const MainContainer = styled.div``;

const Students = () => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Home",
  });

  return (
    <MainContainer>
      <HomeBanner
        backgroundImage={HomeBg}
        logo={IaesteLogo}
        flag={Flag}
        title="Work. Experience. Discover."
      />
      <WhoAreWe />
      <Caption
        TitleText="Are you a student or an employer?"
        SubtitleText="Click on the respective button to be redirected to the necessary page."
        ButtonText="Student"
        ButtonLink="/students"
        ButtonText2="Employers"
        ButtonLink2="/employers"
      />
    </MainContainer>
  );
};

export default Students;
