import styled from 'styled-components';
import { Button } from '@mui/material';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0px;
`;

const Container = styled.div`
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  color: #0b3d59;
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  margin: 0px 0px 50px 0px;
  text-align: center;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  padding: 30px 10px 0 10px;
`;

const CardTitle = styled.h1`
  margin: 0 0 50px 0;
  font-family: 'Futura Std Heavy';
  font-size: 1.3125rem;
  text-align: center;
`;

const CardList = styled.ol``;

const ListItem = styled.li`
  font-size: 1.125rem;
  color: #7a7a7a;
  padding: 0 10px 30px 25px;
`;

const SubmitButton = styled(Button)`
  && {
    margin-top: 50px;
  }
`;

const VisaGuidelines = () => {
  return (
    <MainContainer>
      <Container>
        <Title>General Instruction</Title>
        <Card>
          <CardTitle>
            Points to be keep in mind while applying for the Visa:
          </CardTitle>
          <CardList>
            <ListItem>
              Foreign nationals granted a visa for doing an internship will have
              to register themselves with the FRRO/FRO concerned within 14 days
              of arrival in India for a continuous stay of more than 180 days.
            </ListItem>
            <ListItem>
              In the acceptance documents, IAESTE India provides a letter from
              the institution concerning the internship, including the duration
              and further necessary details.
            </ListItem>
            <ListItem>
              IAESTE India would not be responsible for any VISA Extensions.
              Arrival in India should be on a Student/Intern Visa. Kindly ensure
              to arrive with the right category of visa.
            </ListItem>
          </CardList>
        </Card>
        <SubmitButton variant="contained" color="secondary">
          Start you Application
        </SubmitButton>
      </Container>
    </MainContainer>
  );
};

export default VisaGuidelines;
