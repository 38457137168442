import { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from "react-ga4";

import Banner from '../components/Banner/Banner';
import PartnerWithUs from '../components/PartnerWithUs/PartnerWithUs';
import HowItWorksMinimized from '../components/HowItWorks/Employer/HowItWorksMinimized';
import Caption from '../components/Caption/Caption';
import EmployersBG from '../assets/Banners/PartnerWithUs.jpg';
import RoleAsEmployer from '../components/RoleAsEmployer/RoleAsEmployer';

const MainContainer = styled.div``;

const Employers = () => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Employers",
  });

  return (
    <MainContainer>
      <Banner
        backgroundImage={EmployersBG}
        category="Employers"
        title="Youth, Diversity and Excellence - Hire the best young talent from around the world"
        subtitle="Access to a pool of over 100,000 students from more than 80 countries"
      />
      <PartnerWithUs />
      <RoleAsEmployer />
      <HowItWorksMinimized />
      <Caption
        TitleText="Need to contact us for more specific details?"
        SubtitleText="Reach out to us from our Contact page or drop a mail to coporate@iaeste.in with your query"
        ButtonText="Contact us"
        ButtonLink="/contact"
      />
    </MainContainer>
  );
};

export default Employers;
