import styled from 'styled-components';

import VisaGuidelines from '../VisaGuidelines/VisaGuidelines';
import VisaSearch from '../VisaSearch/VisaSearch';

const MainContainer = styled.div``;

const VisaMain = () => {
  return (
    <MainContainer>
      <VisaGuidelines />
      <VisaSearch />
    </MainContainer>
  );
};

export default VisaMain;
