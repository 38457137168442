import { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from "react-ga4";

import Banner from '../components/Banner/Banner';
import EventsDisplay from '../components/EventsDisplay/EventsDisplay.jsx';
import Caption from '../components/Caption/Caption';
import EventsBG from '../assets/Banners/Events.jpg';

const MainContainer = styled.div``;

const Events = () => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Events",
  });

  return (
    <MainContainer>
      <Banner
        backgroundImage={EventsBG}
        category="Students"
        title="Check out our past events"
        subtitle="Past events organised by our Committies"
      />
      <EventsDisplay />
      <Caption
        TitleText="Need more info on committee specific event?"
        SubtitleText="Reach out to us from our Contact page"
        ButtonText="Contact us"
        ButtonLink="/contact"
      />
    </MainContainer>
  );
};

export default Events;
