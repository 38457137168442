import { useEffect } from 'react';
import styled from 'styled-components';

import Banner from '../components/Banner/Banner';
import Caption from '../components/Caption/Caption';
import Country from '../assets/Banners/Country Flag/Brazil.jpeg';

const MainContainer = styled.div``;

const VisaPreview = () => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <MainContainer>
      <Banner
        backgroundImage={Country}
        category="Students"
        title="Visa Info - Brazil"
        subtitle="Visa information for Incoming Studentss"
      />
      <Caption
        TitleText="Need more information on Visa?"
        SubtitleText="Reach out to us from our Contact page or drop a mail to visa@iaeste.in with your query"
        ButtonText="Contact us"
        ButtonLink="/contact"
      />
    </MainContainer>
  );
};

export default VisaPreview;
